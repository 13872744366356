import { useState, useContext, useMemo } from 'react';

import initApiInstance from '../initApiInstance';
import { LoadingContext } from '../../contexts/LoadingContext';
import { AdminAuthContext } from '../../contexts/AdminAuthContext';

import AdminUserModel from '../../models/AdminUserModel';

/**
 * TODO:
 */
const useFetchAdminUsers = () => {
  const [adminUsers, setAdminUsers] = useState<AdminUserModel[]>([]);

  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const fetchAdminUsers = async () => {
    return new Promise<AdminUserModel[]>(async (resolve) => {
      handleSetServiceLoading('useFetchAdminUsers', true);
      const data: AdminUserModel[] = await apiInstance
        .get(`admin/users`, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        })
        .then((res) => {
          console.debug(`admin/users : `, res);
          return res.data;
        });

      setAdminUsers(data);
      handleSetServiceLoading('useFetchAdminUsers', false);
      resolve(data);
    });
  };

  const adminUsersData = useMemo(() => adminUsers, [adminUsers]);

  return { adminUsersData, fetchAdminUsers };
};

const useCreateAdminUser = () => {
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const createAdminUser = async (data: AdminUserModel) => {
    return new Promise<boolean>(async (resolve) => {
      handleSetServiceLoading('useCreateAdminUser', true);
      const success = await apiInstance
        .post(
          `admin/users`,
          `username=${data.username}&passwordHashed=${data.passwordHashed}&permission=${data.permission}`,
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          },
        )
        .then((res) => {
          console.debug('POST admin/users :', res);
          return res.status === 201;
        })
        .catch((err) => false);
      handleSetServiceLoading('useCreateAdminUser', false);
      resolve(success);
    });
  };
  return { createAdminUser };
};

const useUpdateAdminUser = () => {
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const updateAdminUser = async (data: AdminUserModel) => {
    return new Promise<boolean>(async (resolve) => {
      handleSetServiceLoading('useUpdateAdminUser', true);
      let qsData = `permission=${data.permission}`;
      if (
        data.passwordHashed !== '*'.repeat(data.passwordHashed.length)
      )
        qsData = `password=${data.passwordHashed}&` + qsData;
      const success = await apiInstance
        .put(`admin/users/${data.username}`, qsData, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        })
        .then((res) => {
          console.debug('PUT admin/users :', res);
          return res.status === 200;
        })
        .catch((err) => false);
      handleSetServiceLoading('useUpdateAdminUser', false);
      resolve(success);
    });
  };
  return { updateAdminUser };
};

const useDeleteAdminUser = () => {
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const deleteAdminUser = async (data: AdminUserModel) => {
    return new Promise<boolean>(async (resolve) => {
      handleSetServiceLoading('useDeleteAdminUser', true);
      const success = await apiInstance
        .delete(`admin/users/${data.username}`, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        })
        .then((res) => {
          console.debug('DELETE admin/users :', res);
          return res.status === 200;
        })
        .catch((err) => false);
      handleSetServiceLoading('useDeleteAdminUser', false);
      resolve(success);
    });
  };
  return { deleteAdminUser };
};

export default {
  useFetchAdminUsers,
  useCreateAdminUser,
  useUpdateAdminUser,
  useDeleteAdminUser,
};
