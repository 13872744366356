import React, { useContext, useCallback, useEffect } from 'react';
import { useUpdateEffect } from 'react-use';

import Container from '@material-ui/core/Container';

// TABLE
import MaterialTable, { Column } from 'material-table';
import getMaterialTableIcons from '../../helpers/getMaterialTableIcons';

import { FilterContext } from '../../contexts/FilterContext';
import AdminFilterModel from '../../models/AdminFilterModel';
import AdminFilterService from '../../services/admin/AdminFilterService';
import { AdminIconContext } from '../../contexts/AdminIconContext';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

interface FilterProps {}
/**
 * TIPS: if you have es7 snippets install, you can type rface to get create component template
 */
const Filter: React.FC<FilterProps> = (props) => {
  const [tabValue, setTabValue] = React.useState(0);
  return (
    <React.Fragment>
      <h1>Filter</h1>
      <Container maxWidth="xl">
        <Tabs
          value={tabValue}
          textColor="secondary"
          onChange={(event, newValue) => {
            setTabValue(newValue);
          }}
          aria-label="simple tabs example"
        >
          <Tab label="Configure" />
          <Tab label="Summary" />
        </Tabs>
        {tabValue === 0 && <FilterConfigure />}
        {tabValue === 1 && <FilterSummary />}
      </Container>
    </React.Fragment>
  );
};

const FilterConfigure: React.FC<FilterProps> = (props) => {
  interface TableState {
    columns: Array<Column<AdminFilterModel>>;
    data: AdminFilterModel[];
  }

  const [state, setState] = React.useState<TableState>({
    columns: [
      {
        title: 'order',
        field: 'ordering',
        type: 'numeric',
        validate: (rowData) =>
          !!rowData.ordering && rowData.ordering != '',
      },
      {
        title: 'filterName',
        field: 'filterName',
        editable: 'onAdd',
        validate: (rowData) =>
          !!rowData.filterName && rowData.filterName.trim() !== '',
      },
      {
        title: 'filterType',
        field: 'filterType',
        editable: 'onAdd',
        lookup: {
          service: 'Service',
          channel: 'Channel',
          bankingAgent: 'Banking Agent',
        },
        validate: (rowData) =>
          !!rowData.filterType && rowData.filterType.trim() !== '',
      },
      {
        title: 'labelEn',
        field: 'labelEn',
        validate: (rowData) =>
          !!rowData.labelEn && rowData.labelEn.trim() !== '',
      },
      {
        title: 'labelTh',
        field: 'labelTh',
        validate: (rowData) =>
          !!rowData.labelTh && rowData.labelTh.trim() !== '',
      },
      {
        title: 'shortLabelEn',
        field: 'shortLabelEn',
        validate: (rowData) =>
          !!rowData.shortLabelEn &&
          rowData.shortLabelEn.trim() !== '',
      },
      {
        title: 'shortLabelTh',
        field: 'shortLabelTh',
        validate: (rowData) =>
          !!rowData.shortLabelTh &&
          rowData.shortLabelTh.trim() !== '',
      },
      {
        title: 'buttonIconId',
        field: 'buttonIconId',
        validate: (rowData) =>
          !!rowData.buttonIconId &&
          rowData.buttonIconId.trim() !== '',
      },
      {
        title: 'pinIconId',
        field: 'pinIconId',
        validate: (rowData) =>
          !!rowData.pinIconId && rowData.pinIconId.trim() !== '',
      },
    ],
    data: [],
  });

  const { filterModel, fetchAllMenuSections } = useContext(
    FilterContext,
  );
  const { adminIconsData, fetchAdminIcons } = useContext(
    AdminIconContext,
  );
  const {
    createAdminFilter,
  } = AdminFilterService.useCreateAdminFilter();
  const {
    updateAdminFilter,
  } = AdminFilterService.useUpdateAdminFilter();
  const {
    deleteAdminFilter,
  } = AdminFilterService.useDeleteAdminFilter();

  const fetchMenuWhenDone = useCallback(async () => {
    await fetchAllMenuSections();
    await fetchAdminIcons();
    // adminIconsData;
  }, []);
  useEffect(() => {
    fetchMenuWhenDone();
  }, []);
  useUpdateEffect(() => {
    // console.log('ADMIN filterModel : ', filterModel);
    setState({
      columns: state.columns,
      data: [...filterModel.oriRawData],
    });
  }, [filterModel]);
  useUpdateEffect(() => {
    // console.log('ADMIN adminIconsData : ', adminIconsData);
    setState({
      columns: state.columns.map((obj) =>
        obj.title?.toString().endsWith('IconId')
          ? {
              ...obj,
              lookup: adminIconsData.reduce(
                (prev, curr) => ({
                  ...prev,
                  [curr.iconId]: curr.iconId,
                }),
                {},
              ),
            }
          : obj,
      ),
      data: state.data,
    });
  }, [adminIconsData]);

  return (
    <React.Fragment>
      <MaterialTable
        icons={getMaterialTableIcons()}
        title=""
        columns={state.columns}
        data={state.data}
        editable={{
          onRowAdd: (newData) =>
            new Promise(async (resolve, reject) => {
              if (
                state.columns
                  .map((obj) => obj.field || '')
                  .some(
                    (val) => !Object.keys(newData).includes(val),
                  ) ||
                !Object.values(newData).reduce(
                  (acc, val) => acc && val.toString().trim() !== '',
                  true,
                )
              ) {
                reject();
                return {};
              }
              if (await createAdminFilter(newData)) {
                resolve();
                setState((prevState) => {
                  const data = [...prevState.data];
                  data.push(newData);
                  return { ...prevState, data };
                });
              } else reject();
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(async (resolve, reject) => {
              if (
                state.columns
                  .map((obj) => obj.field || '')
                  .some(
                    (val) => !Object.keys(newData).includes(val),
                  ) ||
                !Object.values(newData).reduce(
                  (acc, val) => acc && val.toString().trim() !== '',
                  true,
                )
              ) {
                reject();
                return {};
              }
              if (await updateAdminFilter(newData)) {
                resolve();
                if (oldData) {
                  setState((prevState) => {
                    const data = [...prevState.data];
                    data[data.indexOf(oldData)] = newData;
                    return { ...prevState, data };
                  });
                }
              } else reject();
            }),
          onRowDelete: (oldData) =>
            new Promise(async (resolve, reject) => {
              if (await deleteAdminFilter(oldData)) {
                resolve();
                setState((prevState) => {
                  const data = [...prevState.data];
                  data.splice(data.indexOf(oldData), 1);
                  return { ...prevState, data };
                });
              } else reject();
            }),
        }}
      />
    </React.Fragment>
  );
};

const FilterSummary: React.FC<FilterProps> = (props) => {
  interface Row {
    service: string;
    channel: string;
    bankingAgent: string;
    amount: string;
  }

  interface TableState {
    columns: Array<Column<Row>>;
    data: Row[];
  }

  const [state, setState] = React.useState<TableState>({
    columns: [
      {
        title: 'Service',
        field: 'service',
      },
      {
        title: 'Channel',
        field: 'channel',
      },
      {
        title: 'Banking Agent',
        field: 'bankingAgent',
        render: (rowData) =>
          rowData.channel === 'bankingAgent'
            ? rowData.bankingAgent
            : '-',
      },
      {
        title: 'Amount',
        field: 'amount',
      },
    ],
    data: [],
  });

  const {
    fetchAdminFilterSummary,
  } = AdminFilterService.useFetchAdminFilterSummary();

  const fetchFilterSummaryWhenDone = useCallback(async () => {
    setState({
      columns: state.columns,
      data: [...(await fetchAdminFilterSummary())],
    });
  }, []);
  useEffect(() => {
    fetchFilterSummaryWhenDone();
  }, []);

  return (
    <React.Fragment>
      <MaterialTable
        icons={getMaterialTableIcons()}
        title=""
        columns={state.columns}
        data={state.data}
        localization={{
          body: { emptyDataSourceMessage: 'L O A D I N G . . .' },
        }}
      />
    </React.Fragment>
  );
};

export default Filter;
