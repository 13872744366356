class AdminIconModel {
  iconId: string;
  iconUrl: string;

  constructor(iconId: string, iconUrl: string) {
    this.iconId = iconId;
    this.iconUrl = iconUrl;
  }
}

export default AdminIconModel;
