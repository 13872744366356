import React, {
  createContext,
  useState,
  useLayoutEffect,
  useEffect,
} from 'react';

import AdminIconService from '../services/admin/AdminIconService';
import AdminIconModel from '../models/AdminIconModel';

interface AdminIconContextProviderProps {}
interface AdminIconContextValue {
  adminIconsData: AdminIconModel[];
  fetchAdminIcons: () => Promise<AdminIconModel[]>;
}

export const AdminIconContext = createContext<AdminIconContextValue>({
  adminIconsData: [],
  fetchAdminIcons: () => new Promise<AdminIconModel[]>((r) => {}),
});

const AdminIconContextProvider: React.FC<AdminIconContextProviderProps> = (
  props,
) => {
  const {
    adminIconsData,
    fetchAdminIcons,
  } = AdminIconService.useFetchAdminIcons();

  return (
    <AdminIconContext.Provider
      value={{
        adminIconsData,
        fetchAdminIcons,
      }}
    >
      {props.children}
    </AdminIconContext.Provider>
  );
};

export default AdminIconContextProvider;
