import React, { createContext, useEffect } from 'react';

import useFetchPreset from '../services/useFetchPreset';
import FilterModel from '../models/FilterModel';

export const PresetContext = createContext({
  presetData: null as any,
  fetchPreset: (
    presetUrl: string,
    filterModel?: FilterModel | undefined,
  ) => new Promise<unknown>((resolve) => {}),
  fetchAllPreset: () => new Promise<unknown>((resolve) => {}),
});

const PresetContextProvider = (props: any) => {
  const {
    data: presetData,
    fetchData: fetchPreset,
    fetchAllData: fetchAllPreset,
  } = useFetchPreset();

  return (
    <PresetContext.Provider
      value={{
        presetData,
        fetchPreset,
        fetchAllPreset,
      }}
    >
      {props.children}
    </PresetContext.Provider>
  );
};

export default PresetContextProvider;
