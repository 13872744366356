import { useEffect, useState, useContext, useMemo } from 'react';

import { LoadingContext } from '../../contexts/LoadingContext';
// import { AdminAuthContext } from '../../contexts/AdminAuthContext';
import initApiInstance from '../initApiInstance';

/**
 * TODO:
 */
const usePostLoginAdminPage = () => {
  const [token, setJwtToken] = useState<string>('');

  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  //   const { updateJwtToken } = useContext(AdminAuthContext);

  const postLogin = async (username: string, password: string) => {
    return new Promise<string>(async (resolve) => {
      handleSetServiceLoading('usePostLoginAdminPage', true);
      const data: string = await apiInstance
        .post(
          `admin/auth`,
          `username=${username}&password=${password}`,
          /*
           * DEFAULT by http protocol
           *
          {
            headers: {
              'content-type':
                'application/x-www-form-urlencoded;charset=utf-8',
            },
          },*/
        )
        .then((res) => {
          console.debug(`admin/auth : `, res);
          return res.data;
        })
        .catch((err) => {
          return '';
        });

      console.debug(`token :`, data);
      setJwtToken(data);
      handleSetServiceLoading('usePostLoginAdminPage', false);
      resolve(data);
    });
  };

  const jwtToken = useMemo(() => token, [token]);

  return { jwtToken, postLogin };
};

export default usePostLoginAdminPage;
