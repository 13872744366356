import React, {
  createContext,
  useState,
  useLayoutEffect,
  useEffect,
} from 'react';

import AdminConfigService from '../services/admin/AdminConfigService';
import AdminConfigModel from '../models/AdminConfigModel';

interface AdminConfigContextProviderProps {}
interface AdminConfigContextValue {
  adminConfigsData: AdminConfigModel[];
  fetchAdminConfigs: () => Promise<AdminConfigModel[]>;
}

export const AdminConfigContext = createContext<
  AdminConfigContextValue
>({
  adminConfigsData: [],
  fetchAdminConfigs: () => new Promise<AdminConfigModel[]>((r) => {}),
});

const AdminConfigContextProvider: React.FC<AdminConfigContextProviderProps> = (
  props,
) => {
  const {
    adminConfigsData,
    fetchAdminConfigs,
  } = AdminConfigService.useFetchAdminConfigs();

  return (
    <AdminConfigContext.Provider
      value={{
        adminConfigsData,
        fetchAdminConfigs,
      }}
    >
      {props.children}
    </AdminConfigContext.Provider>
  );
};

export default AdminConfigContextProvider;
