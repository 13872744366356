import React, {
  useEffect,
  useContext,
  useState,
  useRef,
} from 'react';

import { LinearProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useLocation, useUpdateEffect } from 'react-use';
import styled from 'styled-components/macro';

import MainMap from '../components/MainMap';
import Search from '../components/Search';
import SearchNearMe from '../components/SearchNearMe';
import FilterContextProvider, {
  FilterContext,
} from '../contexts/FilterContext';
import LoadingContextProvider, {
  LoadingContext,
} from '../contexts/LoadingContext';
import MapFilterDataContextProvider, {
  MapFilterDataContext,
} from '../contexts/MapFilterDataContext';
import MapInfoContextProvider from '../contexts/MapInfoContext';
import MultiDrawerStateContextProvider, {
  MultiDrawerStateContext,
} from '../contexts/MultiDrawerStateContext';
import {
  DEFAULT_BREAKPOINT,
  ThemeContext,
} from '../contexts/theme/Theme';
import useCurrentLocation from '../custom-hooks/useCurrentLocation';
import useScreenSize from '../custom-hooks/useScreenSize';
import QueryModel from '../models/QueryModel';
import { LangContext } from '../contexts/LangContext';
import InfoPanel, {
  infoPanelMoreHeight,
} from '../components/InfoPanel';
import MainFilterPanel from '../components/MainFilterPanel';
import InputFocusContextProvider from '../contexts/InputFocusContext';
import { useDebouncedCallback } from 'use-debounce';
import CurrentLocationContextProvider, {
  translateScreenCenterDown,
} from '../contexts/CurrentLocationContext';
import { ScreenModeContext } from '../contexts/ScreenModeContext';

const WAIT_TIME_BEFORE_FETCH = 500;
interface MainPageProps { }
/**
 * TIPS: if you have es7 snippets install, you can type rface to get create component template
 */
const MainPage: React.FC<MainPageProps> = (props) => {
  const { lang, screenMode: screenModeFromParams } = useParams();
  const { screenMode, setScreenMode } = useContext(ScreenModeContext);
  const { width } = useScreenSize();
  setScreenMode(screenModeFromParams);
  console.log('Current width is :', width);
  if (width < 992) {
    setScreenMode('app');
  } else {
    setScreenMode('desktop');
  }
  const { handleSetThemeMode } = useContext(ThemeContext);
  const { combinedDrawerState, drawerStateMap } = useContext(
    MultiDrawerStateContext,
  );
  const {
    coords: currentCoords,
    reReadCurrentLocation,
  } = useCurrentLocation();

  const { isAnyServiceLoading } = useContext(LoadingContext);
  const { switchLang } = useContext(LangContext);
  const {
    selectedChoice,
    fetchDataPoints,
    mapRef,
    keyword,
  } = useContext(MapFilterDataContext);

  // Debounce callback
  const [debouncedFetchDataPoints] = useDebouncedCallback(
    // function
    () => {
      withoutDebouncedFetchDataPoints();
    },
    // delay in ms
    WAIT_TIME_BEFORE_FETCH,
  );

  const withoutDebouncedFetchDataPoints = () => {
    if (mapRef) {
      const center = mapRef.current.getCenter();
      fetchDataPoints(center, selectedChoice, keyword);
    }
  };

  const location = useLocation();

  useEffect(() => {
    console.debug('search', location.search);
    // TODO: add if not location.search -> redirect using the localStorage's lang
    if (lang) {
      const parsed = { lang: lang };
      let langRead;
      if (typeof parsed.lang === 'string') {
        langRead = parsed.lang;
      } else if (Array.isArray(parsed.lang)) {
        throw Error('only accept 1 lang');
      }
      if (langRead) {
        switchLang(langRead);
      }
    }
  }, []);

  // whatever effect that depends on the width
  useEffect(() => {
    console.debug('screenMode', screenMode);
    const mode = screenMode === 'app' ? 'kplus-light' : 'light';
    console.debug('mode', mode);
    handleSetThemeMode(mode);
  }, [screenMode, handleSetThemeMode]);

  // use mock image for the development of the non map parts for now
  const useFake = false;

  /**
   * set center of the screen to current location
   */
  const handleSetCenterToCurrentCoords = () => {
    reReadCurrentLocation();
  };

  /**
   * when change the choice -> call fetch data
   * use debounce to deal with spam toggle
   */
  useUpdateEffect(() => {
    debouncedFetchDataPoints();
  }, [selectedChoice]);

  /**
   * when keyword is updated, call fetch datapoints immediately
   */
  useUpdateEffect(() => {
    withoutDebouncedFetchDataPoints();
  }, [keyword]);

  /**
   * when current coordination change (after click search near me)
   * call fetch data
   */
  useUpdateEffect(() => {
    console.debug(
      'defaultCenter changed -> call fetch data',
      currentCoords,
    );
    fetchDataPoints(currentCoords, selectedChoice);
  }, [currentCoords]);

  return (
    <SMainPage>
      <MapInfoContextProvider>
        {/* comment out search bar for now because don't need to do in the first deliver */}
        {/* InputFocusContextProvider provides info if input bar is focused or not */}
        <InputFocusContextProvider>
          <SSearchWrapper className={`${screenMode}`}>
            <Search />
          </SSearchWrapper>
          <CurrentLocationContextProvider>
            <MainMap
              ref={mapRef}
              useFake={useFake}
              currentCoords={currentCoords}
              debouncedFetchDataPoints={debouncedFetchDataPoints}
              withoutDebouncedFetchDataPoints={
                withoutDebouncedFetchDataPoints
              }
            />
          </CurrentLocationContextProvider>
        </InputFocusContextProvider>
        <SearchNearMeWrapper
          className={`${combinedDrawerState
            ? combinedDrawerState.toLowerCase()
            : ''
            }
              ${screenMode}`}
          moreHeight={
            drawerStateMap['InfoPanel'] === 'MORE'
              ? `calc(${infoPanelMoreHeight} + 30px)`
              : undefined
          }
        >
          <SearchNearMe
            handleClick={handleSetCenterToCurrentCoords}
          />
        </SearchNearMeWrapper>

        <MainFilterPanel />
        <InfoPanel />
        {isAnyServiceLoading && <SLinearProgress />}
      </MapInfoContextProvider>
    </SMainPage>
  );
};

const MainPageContainer: React.FC = () => {
  return (
    <LoadingContextProvider>
      <MultiDrawerStateContextProvider
        drawerStateMap={{
          InfoPanel: 'COLLAPSED',
          MainFilterPanel: 'COLLAPSED',
        }}
      >
        {/* filter must comes before map filter data context */}
        <FilterContextProvider>
          <MapFilterDataContextProvider>
            <MainPage></MainPage>
          </MapFilterDataContextProvider>
        </FilterContextProvider>
      </MultiDrawerStateContextProvider>
    </LoadingContextProvider>
  );
};

export default MainPageContainer;

const SLinearProgress = styled(LinearProgress).attrs((props) => ({
  className: props.className,
}))`
  position: absolute;
  bottom: 100vh; // not sure why i cannot set from the top
  &.hide {
    height: 0;
    transition: height 0.1s;
  }
  transition: height 0.1s;
  &.MuiLinearProgress-colorPrimary {
    background: ${(props) => props.theme.colors.palette.lightPrimary};
  }
  & .MuiLinearProgress-barColorPrimary {
    background: ${(props) => props.theme.colors.palette.primary};
  }
`;

const SMainPage = styled.div`
  height: 100%;
  position: relative;
  font-family: ${(props) => props.theme.fontFamilies.body};
  font-weight: 300;
  overflow: hidden;
`;

const SSearchWrapper = styled.div`
  position: absolute;
  &.app {
    right: 0;
    margin: 15px 10px;
  }
  &.desktop {
    left: 0.7vw;
    top: 1.5vh;
  }
  z-index: 1;
`;

const SearchNearMeWrapper = styled.div.attrs((props) => ({
  className: props.className,
})) <{ moreHeight?: string }>`
  position: absolute;
  &.desktop {
    bottom: 3.5vh;
    right: 3vw;
    transition: bottom 0.2s ease-out; // on the way in
  }
  right: 0;
  &.app {
    margin-right: 10px;
    &.collapsed {
      bottom: calc(20px + 30px);
      transition: bottom 0.2s ease-out; // on the way in
    }
    &.normal {
      bottom: calc(35vh + 30px);
      transition: bottom 0.2s ease-out; // on the way in
    }
    &.more {
      bottom: ${(props) =>
    props.moreHeight ||
    'calc(70vh + 30px)'}; // this will go under the drawer
      transition: bottom 0.2s ease-out; // on the way in
    }
    transition: bottom 0.2s ease-in; // on the way out
  }
`;

// const SHeader = styled.h1`
//   color: ${(props) => props.theme.colors.text.primary};
//   background: ${(props) => props.theme.colors.palette.primary};
//   padding: 10px;
// `;

// const Spar = styled.p`
//   padding: 10px;
//   color: ${(props) => props.theme.colors.text.primary};
//   background: ${(props) => props.theme.colors.palette.secondary};
// `;
