import React, { useCallback, useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import AdminFileService from '../../services/admin/AdminFileService';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

interface UploadProps {}
/**
 * TIPS: if you have es7 snippets install, you can type rface to get create component template
 */
const Upload: React.FC<UploadProps> = (props) => {
  const classes = useStyles();
  const [refresh, setRefresh] = useState<boolean>(true);
  const [history, setHistory] = useState<
    { id: number; uploadDate: Date; totalRecords: number }[]
  >();
  const [fileState, setFileState] = useState<File>();

  // file service
  const {
    fetchUploadSignedUrl,
  } = AdminFileService.useFetchUploadSignedUrl();
  const {
    putFileToSignedUrl,
  } = AdminFileService.usePutFileToSignedUrl();
  const {
    postLocatorDataFromGcs,
  } = AdminFileService.usePostLocatorDataFromGcs();
  const {
    fetchSuccessUploadHistory,
  } = AdminFileService.useFetchSuccessUploadHistory();

  const fetchSuccessUploadHistoryWhenDone = useCallback(async () => {
    setHistory(await fetchSuccessUploadHistory());
  }, []);
  // useUpdateEffect(() => {
  //   console.log(history);
  // }, [history]);
  useEffect(() => {
    if (refresh) fetchSuccessUploadHistoryWhenDone();
    setRefresh(false);
  }, [refresh]);

  function handleChanges(files: FileList | null) {
    // console.log(files);
    if (files && files.length >= 1) setFileState(files[0]);
  }

  async function handleUploadClick(file: File | undefined) {
    if (!file) return;

    const filename = `upload/${file.name}`;
    const signedUrl = await fetchUploadSignedUrl(
      'data',
      filename,
      file.type,
    );
    const publicUrl = signedUrl.split('?')[0];
    const isUploaded = await putFileToSignedUrl(signedUrl, file); // About CORS, see comment inside this function

    if (!isUploaded || !(await postLocatorDataFromGcs(filename))) {
      alert('Failed to upload !');
      return;
    } else {
      setRefresh(true);
      alert('File upload completed !');
    }

    // /**
    //  * TODO: process uploaded file
    //  */
    // if (!(await postLocatorDataFromGcs(filename))) {
    //   alert('Failed to upload !');
    //   return;
    // }

    // // Clean up
    // setRefresh(true);
    // alert('File upload completed !');
  }

  return (
    <React.Fragment>
      <h1>Data Upload</h1>
      <Container className={classes.paper} maxWidth="sm">
        <TextField
          disabled
          color="secondary"
          variant="outlined"
          margin="normal"
          fullWidth
          id="selected-file"
          name="selected-file"
          value={fileState?.name || ''}
        />
        <div>
          <label htmlFor="selecting-file">
            <input
              style={{ display: 'none' }}
              id="selecting-file"
              name="selecting-file"
              type="file"
              onChange={(e) => {
                handleChanges(e.target.files);
              }}
            />
            <Button
              style={{ width: '128px', marginTop: '3vh' }}
              color="secondary"
              variant="contained"
              component="span"
            >
              Choose File
            </Button>
          </label>
          <Button
            style={{ width: '128px', marginTop: '3vh' }}
            className={classes.Sbutton}
            color="secondary"
            variant="contained"
            component="span"
            onClick={async () => await handleUploadClick(fileState)}
          >
            Upload
          </Button>
        </div>
      </Container>
      <Container maxWidth="sm" style={{ marginTop: '5vh' }}>
        <Alert
          variant="filled"
          severity="info"
          style={{
            backgroundColor: '#f1f1f1',
            color: '#9f9f9f',
          }}
        >
          <AlertTitle>Recently Uploaded:</AlertTitle>
          {history &&
            history.map((val) => {
              const date = new Date(val.uploadDate);
              return (
                <React.Fragment key={val.id}>
                  <br />
                  <strong>
                    {date.getDate()}/{date.getMonth()}/
                    {date.getFullYear()} {date.toLocaleTimeString()}
                  </strong>{' '}
                  - Data version <strong>#{val.id}</strong> has{' '}
                  {val.totalRecords} pins.
                </React.Fragment>
              );
            })}
        </Alert>
      </Container>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  Sbutton: {
    marginLeft: '7vw',
  },
  avatar: {
    margin: theme.spacing(-0.5),
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuListItem: {
    '&.active': {
      color: '#4BA55A',
    },
  },
}));

export default Upload;
