import { useState, useContext, useEffect } from 'react';
import { LoadingContext } from '../contexts/LoadingContext';
import initApiInstance from './initApiInstance';
import FilterModel from '../models/FilterModel';
import { AuthContext } from '../contexts/AuthContext';

const useFetchPreset = () => {
  const [data, setData] = useState<any>(null);
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { token } = useContext(AuthContext);

  const injectBankingAgent = (
    data: any,
    filterModel: FilterModel,
  ) => {
    if ('bankingAgent' in data) {
      // inject all banking agents
      return {
        ...data,
        ...filterModel.bankingAgentsNames.reduce(
          (acc, name) => ({ ...acc, [name]: 'bankingAgent' }),
          {},
        ),
      };
    }
    if (Object.values(data).includes('bankingAgent')) {
      // add banking agents
      return { ...data, bankingAgent: 'channel' };
    }
    return data;
  };

  useEffect(() => {
    handleSetServiceLoading('useFetchPreset', true);
  }, []);

  const fetchData = async (
    presetUrl: string,
    filterModel?: FilterModel,
  ) => {
    return new Promise(async (resolve) => {
      handleSetServiceLoading('useFetchPreset', true);
      const data = await apiInstance
        .get(`presets/${presetUrl}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.debug(`presets : `, res.data);
          return res.data;
        });
      const result = filterModel
        ? injectBankingAgent(data, filterModel)
        : data;
      setData(result);
      handleSetServiceLoading('useFetchPreset', false);
      resolve(result);
      // console.log('preset result : ', result);
    });
  };

  const fetchAllData = async () => {
    return new Promise(async (resolve) => {
      handleSetServiceLoading('useFetchPreset', true);
      const data = await apiInstance
        .get(`presets`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.debug(`presets : `, res.data);
          return res.data;
        });
      setData(data);
      handleSetServiceLoading('useFetchPreset', false);
      resolve(data);
      // console.log('all presets : ', data);
    });
  };

  return { data, fetchData, fetchAllData };
};

export default useFetchPreset;
