import React, { useState, useContext } from 'react';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import styled from 'styled-components/macro';
import { InputFocusContext } from '../contexts/InputFocusContext';
import { useDebouncedCallback } from 'use-debounce';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import useFetchSuggestions from '../services/useFetchSuggestions';
import { MapFilterDataContext } from '../contexts/MapFilterDataContext';
import { LangContext } from '../contexts/LangContext';
import { ScreenModeContext } from '../contexts/ScreenModeContext';

const Search: React.FC = () => {
  const {
    isFocus: showMobileFullSc,
    handleSetIsFocus: setShowMobileFullSc,
  } = useContext(InputFocusContext);
  const { handleSetKeyword } = useContext(MapFilterDataContext);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [searchValue, setSearchValue] = useState<string | undefined>(
    undefined,
  );
  const { screenMode } = useContext(ScreenModeContext);
  const { currentLangData } = useContext(LangContext);
  // TODO: add useFetchSuggestions
  const {
    data: suggestions,
    fetchData: fetchSuggestions,
    isLoading: isFetchingSuggestions,
  } = useFetchSuggestions();

  const clearSearchValue = () => {
    setSearchValue('');
    handleSetKeyword('');
  };
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    if (showMobileFullSc) setShowMobileFullSc(false);
  };

  const handleClose = () => {
    clearSearchValue();
    if (showMobileFullSc) setShowMobileFullSc(false);
  };

  const handleInputFocus = () => {
    setShowMobileFullSc(true);
  };

  // Debounce callback
  const [debouncedOnSearchValueChange] = useDebouncedCallback(
    // function
    (keyword: string) => {
      console.debug('call fetch: ', keyword);
      fetchSuggestions(keyword);
    },
    // delay in ms
    200,
  );

  const handleSearchValueChange = (value: string) => {
    setSearchValue(value);
    debouncedOnSearchValueChange(value);
  };

  const handleSelectSuggestion = (suggestion: string | undefined) => {
    // TODO: call fetch then close the full bleed
    console.debug('click suggestion: ', suggestion);
    handleSetKeyword(suggestion);
    setSearchValue(suggestion);
    setShowMobileFullSc(false);
  };

  const handleKeypress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') handleSelectSuggestion(searchValue);
  };

  /**
   * SWrapper -> highlight means has value but is collapsed
   * TODO: fix the react error controll uncontroll switching
   * @param fullscreen
   */
  const renderInside = (fullscreen = false) => {
    console.debug('renderInside. searchValue', searchValue);
    return (
      <SWrapper
        className={`${isCollapsed ? 'collapsed' : ''} ${
          fullscreen ? 'fullsc' : ''
        } ${
          isCollapsed && searchValue !== undefined ? 'highlight' : ''
        } ${screenMode}`}
      >
        {screenMode != 'desktop' && (
          <SToggler onClick={handleToggleCollapse}>
            <SChevron>
              {isCollapsed ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </SChevron>
          </SToggler>
        )}

        <SInputWrapper>
          <SInput
            className={`${screenMode}`}
            value={searchValue}
            autoFocus={fullscreen}
            onKeyPress={handleKeypress}
            onFocus={() => handleInputFocus()}
            onChange={(e) => handleSearchValueChange(e.target.value)}
            placeholder={currentLangData.search}
          />
        </SInputWrapper>
        <SToggler
          className={`cancel-icon ${screenMode} clickable`}
          onClick={handleClose}
        >
          {(searchValue || showMobileFullSc) && <CancelIcon />}
        </SToggler>
      </SWrapper>
    );
  };

  const renderSuggestionsItems = () => {
    return suggestions.length ? (
      suggestions.map((suggestion) => (
        <SuggestionItem
          className="clickable"
          key={suggestion}
          onClick={() => handleSelectSuggestion(suggestion)}
        >
          {suggestion}
        </SuggestionItem>
      ))
    ) : (
      //  TODO: handle two languages
      <LoadingOrNotfound>
        {currentLangData.noSuggestion}
      </LoadingOrNotfound>
    );
  };
  const renderSuggestions = () => {
    //  TODO: handle two languages
    return (
      <SuggestionWrapper>
        <SuggestionTitle>
          {currentLangData.suggestions}
        </SuggestionTitle>
        <SuggestionsList>
          {isFetchingSuggestions ? (
            <LoadingOrNotfound>
              {currentLangData.loading}
            </LoadingOrNotfound>
          ) : (
            renderSuggestionsItems()
          )}
        </SuggestionsList>
      </SuggestionWrapper>
    );
  };
  const renderMobileFullSc = () => {
    return (
      <SMobileFull className={`${screenMode}`}>
        {renderInside(true)}
        {searchValue && (
          <SSearchByWrapper
            onClick={() => handleSelectSuggestion(searchValue)}
          >
            {/* below is the Circular load instead of  loading string */}
            <SearchIcon></SearchIcon>
            {`${currentLangData.searchBy} "${searchValue}"`}
          </SSearchByWrapper>
        )}

        {/* suggestions */}
        {searchValue && renderSuggestions()}
      </SMobileFull>
    );
  };
  return !showMobileFullSc ? renderInside() : renderMobileFullSc();
};

export default Search;

const SChevron = styled.div`
  margin-left: 2.5vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SSearchByWrapper = styled.div`
  // SearchIcon. but couldnt figure out how to use selector for maticon
  & > * {
    margin-right: 0.5rem;
    color: ${(props) => props.theme.colors.text.primary};
  }
  display: flex;
  margin: 1rem 0;
  font-size: ${(props) => props.theme.fontSizes.medium};
`;

const SMobileFull = styled.div`
  &.app {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 1rem;
  }
  &.desktop {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 1rem;
    width: 29vw;
    box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.25);
  }

  background: ${(props) => props.theme.colors.common.white};
`;

const SToggler = styled.div.attrs((props) => ({
  className: props.className,
}))`
  height: 6vh;
  display: flex;
  width: 7vw;
  @media screen and (min-width: 360px) and (max-width: 370px) {
    width: 8vw;
  }
  @media screen and (min-width: 1020px) and (min-height: 760px) {
    width: 4vw;
  }
  @media screen and (min-width: 1350px) and (min-height: 1020px) {
    width: 3vw;
  }
  align-items: center;
  color: ${(props) => props.theme.colors.text.primary};
  justify-content: center;
  &.cancel-icon {
    &.app {
      margin-right: 0.5rem;
    }
  }
  &.desktop {
    margin-right: 0.5vw;
    width: 3vw;
  }
`;

const SInput = styled.input`
  &.app {
    width: 74vw;
    font-size: ${(props) => props.theme.fontSizes.small};
    &:focus {
      width: 70vw;
    }
  }
  &.desktop {
    width: 22.6vw;
    border-radius: 5px;
    font-size: ${(props) => props.theme.fontSizes.smallMedium};
    &:focus {
      width: 22.6vw;
    }
  }
  border: none;
  line-height: 2;
  padding: 5px 10px;
  outline: none;
  font-family: ${(props) => props.theme.fontFamilies.body};
`;

const SInputWrapper = styled.div`
  display: flex;
`;

const SWrapper = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  border-radius: 5px;
  justify-content: flex-end;
  align-items: center;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.18) 1px 1px 2px 0px;
  &.app {
    &.collapsed {
      & ${SInputWrapper} {
        display: none;
        overflow: hidden;
      }
    }
    & ${SInputWrapper} {
      width: 78vw;
      @media screen and (min-width: 360px) {
        width: 75vw;
    }
    @media screen and (min-width: 370px) and (min-height: 650px) {
      width: 79vw;
  }
    @media screen and (min-width: 400px) {
      width: 78vw;
  }
      @media screen and (min-width: 768px) and (min-height:1023px) {
        width: 82vw;
      }
      @media screen and (min-width: 1020px) and (min-height:760px) {
        width: 88.5vw;
      }
      @media screen and (min-width: 1020px) and (min-height:1350px) {
        width: 89.3vw;
      }
      @media screen and (min-width: 1350px) and (min-height:1020px) {
        width: 91.3vw;
      }
    &.fullsc {
      border: solid 1px rgba(0, 0, 0, 0.18);
      box-shadow: rgba(0, 0, 0, 0.08) 1px 1px 2px 0px;
    }

    // show that has value but is collapsed
    &.highlight {
      border: solid 2px rgba(0, 126, 255, 0.58);
      box-shadow: rgba(0, 126, 255, 0.22) 1px 1px 3px 3px;
    }
  }
  &.desktop {
    height: 6vh;
  }
`;

const SuggestionTitle = styled.div`
  font-size: ${(props) => props.theme.fontSizes.medium};
  margin-bottom: 0.5rem;
`;
const SuggestionsList = styled.div`
  font-size: ${(props) => props.theme.fontSizes.smallMedium};

  // scroll if overflow
  overflow-y: scroll;
  height: 75vh;
`;
const SuggestionItem = styled.div`
  border-bottom: solid 1px ${(props) => props.theme.colors.grey.light};
  padding: 0.5rem 0;

  // oneline ...
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SuggestionWrapper = styled.div``;
const LoadingOrNotfound = styled.div`
  text-align: center;
`;
