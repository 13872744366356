import { useState, useContext, useMemo } from 'react';

import initApiInstance from '../initApiInstance';
import { LoadingContext } from '../../contexts/LoadingContext';
import { AdminAuthContext } from '../../contexts/AdminAuthContext';

import AdminConfigModel from '../../models/AdminConfigModel';

/**
 * TODO:
 */
const useFetchAdminConfigs = () => {
  const [adminConfigs, setAdminConfigs] = useState<
    AdminConfigModel[]
  >([]);

  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const fetchAdminConfigs = async () => {
    return new Promise<AdminConfigModel[]>(async (resolve) => {
      handleSetServiceLoading('useFetchAdminConfigs', true);
      const data: AdminConfigModel[] = await apiInstance
        .get(`admin/configs`, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        })
        .then((res) => {
          console.debug(`admin/configs : `, res);
          return res.data;
        });

      setAdminConfigs(data);
      handleSetServiceLoading('useFetchAdminConfigs', false);
      resolve(data);
    });
  };

  const adminConfigsData = useMemo(() => adminConfigs, [
    adminConfigs,
  ]);

  return { adminConfigsData, fetchAdminConfigs };
};

const useCreateAdminConfig = () => {
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const createAdminConfig = async (data: AdminConfigModel) => {
    return new Promise<boolean>(async (resolve) => {
      handleSetServiceLoading('useCreateAdminConfig', true);
      const success = await apiInstance
        .post(
          `admin/configs`,
          `key=${data.key}&value=${data.value}`,
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          },
        )
        .then((res) => {
          console.debug('POST admin/configs :', res);
          return res.status === 201;
        })
        .catch((err) => false);
      handleSetServiceLoading('useCreateAdminConfig', false);
      resolve(success);
    });
  };
  return { createAdminConfig };
};

const useUpdateAdminConfig = () => {
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const updateAdminConfig = async (data: AdminConfigModel) => {
    return new Promise<boolean>(async (resolve) => {
      handleSetServiceLoading('useUpdateAdminConfig', true);
      const success = await apiInstance
        .put(
          `admin/configs/${data.key}?newValue=${data.value}`,
          undefined,
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          },
        )
        .then((res) => {
          console.debug('PUT admin/configs :', res);
          return res.status === 200;
        })
        .catch((err) => false);
      handleSetServiceLoading('useUpdateAdminConfig', false);
      resolve(success);
    });
  };
  return { updateAdminConfig };
};

const useDeleteAdminConfig = () => {
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const deleteAdminConfig = async (data: AdminConfigModel) => {
    return new Promise<boolean>(async (resolve) => {
      handleSetServiceLoading('useDeleteAdminConfig', true);
      const success = await apiInstance
        .delete(`admin/configs/${data.key}`, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        })
        .then((res) => {
          console.debug('DELETE admin/configs :', res);
          return res.status === 200;
        })
        .catch((err) => false);
      handleSetServiceLoading('useDeleteAdminConfig', false);
      resolve(success);
    });
  };
  return { deleteAdminConfig };
};

export default {
  useFetchAdminConfigs,
  useCreateAdminConfig,
  useUpdateAdminConfig,
  useDeleteAdminConfig,
};
