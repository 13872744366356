import React, { useContext } from 'react';

import './App.css';
import { ReactComponent as Spinner } from './images/Spinner.svg';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import LangContextProvider from './contexts/LangContext';
import ThemeContextProvider from './contexts/theme/Theme';
import AdminAuthContextProvider from './contexts/AdminAuthContext';
import LoadingContextProvider from './contexts/LoadingContext';
import MainPage from './pages/MainPage';
import AdminPage from './pages/AdminPage';
import 'font-awesome/css/font-awesome.css';
import ScreenModeContextProvider from './contexts/ScreenModeContext';
import { AuthContext } from './contexts/AuthContext';

const App = () => {
  const { tokenLoading, tokenAlert } = useContext(AuthContext);
  //get token
  const retryGetToken = () => {
    return (
      <div className="loadScreen">
        <div className="alertBox">
          <div className="title">Can not get the token.</div>
          <div className="subtitle">Please try again.</div>
          <div
            className="retryButton"
            onClick={() => window.location.reload()}
          >
            Retry
          </div>
        </div>
      </div>
    );
  };
  return (
    <ScreenModeContextProvider>
      <LangContextProvider>
        <ThemeContextProvider>
          <Router>
            <Switch>
              <Route exact path="/">
                <Redirect to="/th/app" />
              </Route>
              <Route
                exact
                path="/:lang"
                render={(props) => (
                  <Redirect to={`/${props.match.params.lang}/app`} />
                )}
              ></Route>
              <Route
                exact
                path="/:lang/:screenMode"
                render={(props) =>
                  props.match.params.screenMode.toLowerCase() ===
                  'admin' ? (
                    <LoadingContextProvider>
                      <AdminAuthContextProvider>
                        <AdminPage />
                      </AdminAuthContextProvider>
                    </LoadingContextProvider>
                  ) : tokenLoading ? (
                    <div className="loadScreen">
                      <Spinner />
                    </div>
                  ) : tokenAlert ? (
                    retryGetToken()
                  ) : (
                    <MainPage />
                  )
                }
              ></Route>
              <Route exact path="/:lang/:screenMode/:presetUrl">
                {tokenLoading ? (
                  <div className="loadScreen">
                    <Spinner />
                  </div>
                ) : tokenAlert ? (
                  retryGetToken()
                ) : (
                  <MainPage />
                )}
              </Route>
              {/* add other routes here */}
            </Switch>
          </Router>
        </ThemeContextProvider>
      </LangContextProvider>
    </ScreenModeContextProvider>
  );
};

export default App;
