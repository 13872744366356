import React, { useContext } from 'react';

import styled from 'styled-components/macro';

import { MapInfoContext } from '../contexts/MapInfoContext';
import BottomDrawer from './BottomDrawer';
import DirectionBar from './DirectionBar';
import { LangContext } from '../contexts/LangContext';
import { MultiDrawerStateContext } from '../contexts/MultiDrawerStateContext';
import { FilterContext } from '../contexts/FilterContext';
import { ScreenModeContext } from '../contexts/ScreenModeContext';

export const infoPanelMoreHeight = '60vh';

const InfoPanel = () => {
  const { drawerStateMap } = useContext(MultiDrawerStateContext);
  const { viewingPoint } = useContext(MapInfoContext);
  const { currentLangData } = useContext(LangContext);
  const { filterModel } = useContext(FilterContext);
  const { screenMode } = useContext(ScreenModeContext);
  console.debug('viewingPoint', viewingPoint);
  if (!viewingPoint) return null;

  const getRowsForViewingPoint = () => {
    return [
      {
        label: `${currentLangData.availableServices}`,
        value: viewingPoint.services
          .filter(
            (service: { name: string; logo: any }) =>
              !!filterModel.getLabelOfName(service.name),
          )
          .map((service: { name: string; logo: any }) => {
            return `${filterModel.getLabelOfName(service.name)}`;
          })
          .join(', '),
      },
      {
        label: `${currentLangData.channel}`,
        value: viewingPoint.filterLabel,
      },
      {
        label: `${currentLangData.name}`,
        value: viewingPoint.name,
      },
      {
        label: `${currentLangData.address}`,
        value: viewingPoint.address,
      },
      {
        label: `${currentLangData.officeHours}`,
        value: viewingPoint.officeHour || '-',
      },
      {
        label: `${currentLangData.contact}`,
        value: viewingPoint.contact || '-',
      },
    ];
  };
  return (
    <BottomDrawer
      drawerName="InfoPanel"
      heights={{ more: infoPanelMoreHeight }}
    >
      <SData className={`${screenMode}`}>
        <STable className={`${screenMode}`}>
          <tbody>
            {getRowsForViewingPoint().map((item: any) => (
              <tr key={item.label}>
                <STableLable className={`${screenMode}`}>
                  {item.label} :{' '}
                </STableLable>
                <td>{item.value}</td>
              </tr>
            ))}
          </tbody>
        </STable>
      </SData>
      {drawerStateMap.InfoPanel != 'COLLAPSED' && <DirectionBar />}
    </BottomDrawer>
  );
};

export default InfoPanel;
const SData = styled.div`
  font-family: ${(props) => props.theme.fontFamilies.body};
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSizes.small};
  color: #4d4d4d;
  padding: 0 7vw;

  margin-top: 1.5vh;
  &.desktop {
    padding: 0 2vw;
    margin-bottom: 5vh;
  }
`;

const STableLable = styled.td`
  vertical-align: top;
  font-weight: 600;
  width: 25vw;
  &.desktop {
    width: 8vw;
  }
`;

const STable = styled.table`
  width: 100%;
  line-height: 3.3vh;
  &.desktop {
    line-height: 4.5vh;
  }
`;
