import React, {
  useContext,
  useCallback,
  useEffect,
  forwardRef,
  useRef,
} from 'react';
import { useUpdateEffect } from 'react-use';
import Container from '@material-ui/core/Container';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// TABLE
import MaterialTable, { Column, MTableAction } from 'material-table';
import getMaterialTableIcons from '../../helpers/getMaterialTableIcons';

import { AdminIconContext } from '../../contexts/AdminIconContext';
import AdminIconService from '../../services/admin/AdminIconService';
import AdminFileService from '../../services/admin/AdminFileService';
import AdminIconModel from '../../models/AdminIconModel';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

interface IconProps {}
/**
 * TIPS: if you have es7 snippets install, you can type rface to get create component template
 */
const Icon: React.FC<IconProps> = (props) => {
  interface Row {
    preview: string;
    id: string;
    url: string;
  }

  interface TableState {
    columns: Array<Column<Row>>;
    data: Row[];
  }

  const [state, setState] = React.useState<TableState>({
    columns: [
      { title: ' ', field: 'blank', width: '1px', sorting: false },
      {
        title: 'Preview',
        field: 'preview',
        editable: 'never',
        width: '10%',
        render: (rowData) =>
          rowData.preview !== null ? (
            <img
              src={rowData.url}
              style={{
                width: 50,
              }}
            />
          ) : (
            ''
          ),
      },
      {
        title: 'id',
        field: 'id',
        validate: (rowData) => rowData.id.trim() !== '',
        width: '20%',
      },
      {
        title: 'URL',
        field: 'url',
        editable: 'never',
        width: '70%',
        cellStyle: { whiteSpace: 'nowrap' },
      },
    ],
    data: [],
  });

  const { adminIconsData, fetchAdminIcons } = useContext(
    AdminIconContext,
  );
  const { createAdminIcon } = AdminIconService.useCreateAdminIcon();
  const { deleteAdminIcon } = AdminIconService.useDeleteAdminIcon();
  const {
    fetchUploadSignedUrl,
  } = AdminFileService.useFetchUploadSignedUrl();
  const {
    putFileToSignedUrl,
  } = AdminFileService.usePutFileToSignedUrl();
  const fetchAdminIconsWhenDone = useCallback(
    async () => await fetchAdminIcons(),
    [],
  );
  useEffect(() => {
    fetchAdminIconsWhenDone();
  }, []);
  useUpdateEffect(() => {
    // console.log('ADMIN adminIconsData : ', adminIconsData);
    setState({
      columns: state.columns,
      data: adminIconsData.map((icon) => {
        return {
          preview: icon.iconUrl,
          id: icon.iconId,
          url: icon.iconUrl,
        };
      }),
    });
  }, [adminIconsData]);

  let icons = getMaterialTableIcons();
  icons.Add = forwardRef((props, ref) => (
    <CloudUploadIcon {...props} ref={ref} />
  ));

  const inputIconFile = useRef<HTMLInputElement>(null);

  return (
    <React.Fragment>
      <h1>Icon Manager</h1>
      <Container maxWidth="md">
        <Alert
          variant="filled"
          severity="info"
          style={{
            marginBottom: '2%',
            backgroundColor: '#f1f1f1',
            color: '#9f9f9f',
          }}
        >
          <AlertTitle>Recommended image dimension:</AlertTitle>
          Locator Pin = <strong>68x98</strong> px
          <br />
          Filter Icon = <strong>595x595</strong> px
        </Alert>
      </Container>
      <Container maxWidth="md">
        <input
          type="file"
          id="icon"
          ref={inputIconFile}
          style={{ display: 'none' }}
        />
        <MaterialTable
          icons={icons}
          title=""
          columns={state.columns}
          data={state.data}
          options={{ actionsColumnIndex: 1 }}
          initialFormData={{
            preview: null,
            id: '',
            url: '< URL >',
          }}
          components={{
            Action: (props) => {
              if (
                // only change 'addRow' button
                typeof props.action !== typeof Function &&
                props.action.tooltip === 'Add'
              ) {
                if (!props.action.oldOnClick)
                  props.action.oldOnClick = props.action.onClick;

                props.action.onClick = () => {
                  if (inputIconFile && inputIconFile.current)
                    inputIconFile.current.click();
                  props.action.oldOnClick();
                };
              }
              return <MTableAction {...props} />;
            },
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise(async (resolve, reject) => {
                if (
                  inputIconFile &&
                  inputIconFile.current &&
                  inputIconFile.current.files &&
                  inputIconFile.current.files[0]
                ) {
                  if (!newData.id || newData.id.trim() === '') {
                    reject();
                    return;
                  }

                  const uploadFile = inputIconFile.current.files[0];
                  const filename = `icon/${uploadFile.name}`;
                  const signedUrl = await fetchUploadSignedUrl(
                    'public',
                    filename,
                    uploadFile.type,
                  );
                  const publicUrl = signedUrl.split('?')[0];
                  const isUploaded = await putFileToSignedUrl(
                    signedUrl,
                    uploadFile,
                  ); // About CORS, see comment inside this function

                  if (isUploaded) {
                    newData.url = publicUrl;
                    newData.preview = publicUrl;
                    if (
                      await createAdminIcon(
                        new AdminIconModel(newData.id, newData.url),
                      )
                    ) {
                      setState((prevState) => {
                        const data = [...prevState.data];
                        data.push(newData);
                        return { ...prevState, data };
                      });
                    }
                  }
                } else {
                  alert('Invalid file selection.');
                }
                resolve();
              }),
            // onRowUpdate: (newData, oldData) =>
            //   new Promise(async (resolve, reject) => {
            //     // onButtonClick();
            //     // if (await updateAdminUser(newData)) {
            //     //   if (
            //     //     newData.passwordHashed !==
            //     //     '*'.repeat(newData.passwordHashed.length)
            //     //   )
            //     //     newData.passwordHashed = '*'.repeat(8);
            //     //   resolve();
            //     //   if (oldData) {
            //     //     setState((prevState) => {
            //     //       const data = [...prevState.data];
            //     //       data[data.indexOf(oldData)] = newData;
            //     //       return { ...prevState, data };
            //     //     });
            //     //   }
            //     // } else reject();
            //   }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                if (
                  await deleteAdminIcon(
                    new AdminIconModel(oldData.id, oldData.url),
                  )
                ) {
                  resolve();
                  setState((prevState) => {
                    const data = [...prevState.data];
                    data.splice(data.indexOf(oldData), 1);
                    return { ...prevState, data };
                  });
                } else reject();
              }),
          }}
        />
      </Container>
    </React.Fragment>
  );
};

export default Icon;
