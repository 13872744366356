import React, {
  createContext,
  useState,
  useLayoutEffect,
  useEffect,
} from 'react';

import usePostLoginAdminPage from '../services/admin/usePostLoginAdminPage';

interface AdminAuthContextProviderProps {}
interface AdminAuthContextValue {
  isAuthenticated: boolean;
  userHasAuthenticated: (isAuthenticated: boolean) => void;
  jwtToken: string;
  // updateJwtToken: (jwtToken: string) => void;
  postLogin: (usr: string, pwd: string) => Promise<string>;
}

export const AdminAuthContext = createContext<AdminAuthContextValue>({
  isAuthenticated: false,
  userHasAuthenticated: (isAuthenticated: boolean) => {},
  jwtToken: '',
  // updateJwtToken: (jwtToken: string) => {},
  postLogin: (usr: string, pwd: string) =>
    new Promise<string>((r) => {}),
});

const AdminAuthContextProvider: React.FC<AdminAuthContextProviderProps> = (
  props,
) => {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  // const [jwtToken, updateJwtToken] = useState('');
  const { jwtToken, postLogin } = usePostLoginAdminPage();

  return (
    <AdminAuthContext.Provider
      value={{
        isAuthenticated,
        userHasAuthenticated,
        jwtToken,
        postLogin,
      }}
    >
      {props.children}
    </AdminAuthContext.Provider>
  );
};

export default AdminAuthContextProvider;
