import { useContext, useState } from 'react';
import { LoadingContext } from '../contexts/LoadingContext';
import initApiInstance from './initApiInstance';

// const getMockedData = (keyword: string) => new Array(20).fill(null).map((each: any, index: number) => `${keyword}${index}`)

const useFetchToken = async () => {
  const apiInstance = initApiInstance();
  const token = await apiInstance.get(`token`).then((res) => {
    return res;
  });
  return token;
};

export default useFetchToken;
