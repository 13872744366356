import React, { useEffect, useContext, useState } from 'react';
import {
  Route,
  NavLink,
  Redirect,
  HashRouter,
} from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';

// TOP BAR
import LinearProgress from '@material-ui/core/LinearProgress';

// MENU
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DashboardIcon from '@material-ui/icons/Dashboard';
import ImageIcon from '@material-ui/icons/Image';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';

import kbankLogo from '../images/kbank-logo.png';

// VIEW CONTENT
import Upload from '../components/admin/Upload';
import Config from '../components/admin/Config';
import Filter from '../components/admin/Filter';
import Preset from '../components/admin/Preset';
import Icon from '../components/admin/Icon';
import User from '../components/admin/User';

// CONTEXT
import { LoadingContext } from '../contexts/LoadingContext';
import { AdminAuthContext } from '../contexts/AdminAuthContext';
import AdminUserContextProvider from '../contexts/AdminUserContext';
import AdminConfigContextProvider from '../contexts/AdminConfigContext';
import AdminIconContextProvider from '../contexts/AdminIconContext';
import FilterContextProvider from '../contexts/FilterContext';
import PresetContextProvider from '../contexts/PresetContext';

interface AdminPageProps {}
/**
 * TIPS: if you have es7 snippets install, you can type rface to get create component template
 */
const AdminPage: React.FC<AdminPageProps> = (props) => {
  const { isAuthenticated } = useContext(AdminAuthContext);

  return isAuthenticated ? <Dashboard /> : <Login />;
};
export default AdminPage;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {new Date().getFullYear()}{' '}
      <Link color="inherit" href="https://www.kasikornbank.com/">
        KASIKORNBANK PCL.
      </Link>
      {' ALL RIGHTS RESERVED.'}
    </Typography>
  );
}

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#4BA55A',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(-0.5),
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    height: '100%',
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuListItem: {
    '&.active': {
      color: '#4BA55A',
    },
  },
}));

function Login() {
  const classes = useStyles();
  //   const { postLogin } = usePostLoginAdminPage();

  const { userHasAuthenticated, postLogin } = useContext(
    AdminAuthContext,
  );
  const { isAnyServiceLoading } = useContext(LoadingContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  function validateForm() {
    return username.length > 0 && password.length > 0;
  }

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    // console.debug('User: ', username, ' | Password: ', password);

    const jwtToken: string = await postLogin(username, password);
    console.debug('jwtToken:', jwtToken);

    if (jwtToken === '') {
      alert('Invalid Username or Password !');
      userHasAuthenticated(false);
      return;
    }

    let decoded: {
      username: string;
      permission: string;
      iat: Date;
    } = jwt_decode(jwtToken);
    console.debug('decoded jwt:', decoded);
    if (decoded.username.toLowerCase() !== username.toLowerCase()) {
      alert('Invalid Username or Password !!');
      userHasAuthenticated(false);
      return;
    }
    userHasAuthenticated(true);
    // history.push('/th/admin/');
  }

  return (
    <MuiThemeProvider theme={theme}>
      {isAnyServiceLoading && <LinearProgress color="secondary" />}
      {!isAnyServiceLoading && (
        <LinearProgress
          color="secondary"
          variant="determinate"
          value={100}
        />
      )}
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Container maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <div style={{ display: 'flex' }}>
              <Typography
                component="h1"
                variant="h5"
                style={{ color: '#999999' }}
              >
                KASIKORNBANK
              </Typography>
              <Avatar className={classes.avatar} src={kbankLogo}>
                <LockOutlinedIcon />
              </Avatar>
            </div>
            <form className={classes.form} onSubmit={handleSubmit}>
              <TextField
                color="secondary"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                onChange={(e) => setUsername(e.target.value)}
                autoFocus
              />
              <TextField
                color="secondary"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormControlLabel
                control={
                  <Checkbox value="remember" color="secondary" />
                }
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
                disabled={!validateForm()}
              >
                Login
              </Button>
              {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
            </form>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      </div>
    </MuiThemeProvider>
  );
}

function Dashboard() {
  const classes = useStyles();
  const { handleSetServiceLoading, isAnyServiceLoading } = useContext(
    LoadingContext,
  );
  const { jwtToken } = useContext(AdminAuthContext);

  let decoded: {
    username: string;
    permission: string;
    iat: Date;
  } = jwt_decode(jwtToken);

  const isAllowUpload = ['admin'].includes(
    decoded.permission.toLowerCase(),
  );
  const isAllowConfig = ['admin'].includes(
    decoded.permission.toLowerCase(),
  );
  const isAllowFilter = ['admin'].includes(
    decoded.permission.toLowerCase(),
  );
  const isAllowPreset = ['admin', 'pm'].includes(
    decoded.permission.toLowerCase(),
  );
  const isAllowIcon = ['admin'].includes(
    decoded.permission.toLowerCase(),
  );
  const isAllowUser = ['admin'].includes(
    decoded.permission.toLowerCase(),
  );

  useEffect(() => {
    // ignore initial loading
    handleSetServiceLoading('useFetchAllMenuSections', false); // filter
    handleSetServiceLoading('useFetchPreset', false); // preset
  }, []);

  return (
    <HashRouter>
      <MuiThemeProvider theme={theme}>
        {isAnyServiceLoading && <LinearProgress color="secondary" />}
        {!isAnyServiceLoading && (
          <LinearProgress
            color="secondary"
            variant="determinate"
            value={100}
          />
        )}
        <CssBaseline />
        <div
          style={{
            width: '100vw',
            height: 'fit-content',
            minHeight: '100vh',
            display: 'flex',
          }}
        >
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(classes.drawerPaper),
            }}
            open={true}
          >
            {/* <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div> */}
            <Divider />
            <List>
              <div>
                {isAllowUpload && (
                  <ListItem
                    button
                    className={classes.menuListItem}
                    component={NavLink}
                    to="/upload"
                  >
                    <ListItemIcon>
                      <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Data Upload" />
                  </ListItem>
                )}
                {isAllowConfig && (
                  <ListItem
                    button
                    className={classes.menuListItem}
                    component={NavLink}
                    to="/config"
                  >
                    <ListItemIcon>
                      <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Configuration" />
                  </ListItem>
                )}
                {isAllowFilter && (
                  <ListItem
                    button
                    className={classes.menuListItem}
                    component={NavLink}
                    to="/filter"
                  >
                    <ListItemIcon>
                      <LayersIcon />
                    </ListItemIcon>
                    <ListItemText primary="Filter" />
                  </ListItem>
                )}
                {isAllowPreset && (
                  <ListItem
                    button
                    className={classes.menuListItem}
                    component={NavLink}
                    to="/preset"
                  >
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Preset" />
                  </ListItem>
                )}
                {isAllowIcon && (
                  <ListItem
                    button
                    className={classes.menuListItem}
                    component={NavLink}
                    to="/icon"
                  >
                    <ListItemIcon>
                      <ImageIcon />
                    </ListItemIcon>
                    <ListItemText primary="Icon Manager" />
                  </ListItem>
                )}
                {isAllowUser && (
                  <ListItem
                    button
                    className={classes.menuListItem}
                    component={NavLink}
                    to="/user"
                  >
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="User Manager" />
                  </ListItem>
                )}
              </div>
            </List>
            {/* <Divider />
          <List>{mainListItems}</List> */}
            {/* <Divider />
          <List>{secondaryListItems}</List> */}
          </Drawer>
          <Container maxWidth="xl">
            <CssBaseline />
            <div className={classes.paper}>
              <div style={{ display: 'flex' }}>
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ color: '#999999' }}
                >
                  KASIKORNBANK
                </Typography>
                <Avatar className={classes.avatar} src={kbankLogo}>
                  <LockOutlinedIcon />
                </Avatar>
              </div>

              {/* CONTENT */}
              <Route exact path="/">
                {isAllowUpload ? (
                  <Redirect to="/upload" />
                ) : (
                  <Redirect to="/preset" />
                )}
              </Route>
              {isAllowUpload && (
                <Route path="/upload" component={Upload} />
              )}
              <AdminConfigContextProvider>
                {isAllowConfig && (
                  <Route path="/config" component={Config} />
                )}
              </AdminConfigContextProvider>
              <AdminIconContextProvider>
                <FilterContextProvider>
                  {isAllowFilter && (
                    <Route path="/filter" component={Filter} />
                  )}
                  <PresetContextProvider>
                    {isAllowPreset && (
                      <Route path="/preset" component={Preset} />
                    )}
                  </PresetContextProvider>
                </FilterContextProvider>
                {isAllowIcon && (
                  <Route path="/icon" component={Icon} />
                )}
              </AdminIconContextProvider>
              <AdminUserContextProvider>
                {isAllowUser && (
                  <Route path="/user" component={User} />
                )}
              </AdminUserContextProvider>
            </div>
            <Box mt={8}>
              <Copyright />
            </Box>
          </Container>
        </div>
      </MuiThemeProvider>
    </HashRouter>
  );
}
