import React, { useContext, useCallback, useEffect } from 'react';
import { useUpdateEffect } from 'react-use';

import Container from '@material-ui/core/Container';

// TABLE
import MaterialTable, { Column } from 'material-table';
import getMaterialTableIcons from '../../helpers/getMaterialTableIcons';

import { AdminConfigContext } from '../../contexts/AdminConfigContext';
import AdminConfigModel from '../../models/AdminConfigModel';
import AdminConfigService from '../../services/admin/AdminConfigService';

interface ConfigProps {}
/**
 * TIPS: if you have es7 snippets install, you can type rface to get create component template
 */
const Config: React.FC<ConfigProps> = (props) => {
  interface TableState {
    columns: Array<Column<AdminConfigModel>>;
    data: AdminConfigModel[];
  }

  const [state, setState] = React.useState<TableState>({
    columns: [
      { title: ' ', field: 'blank', width: '1px', sorting: false },
      { title: 'Key', field: 'key', editable: 'onAdd' },
      {
        title: 'Value',
        field: 'value',
        validate: (rowData) =>
          !!rowData.value && rowData.value.trim() !== '',
      },
    ],
    data: [],
  });

  const { adminConfigsData, fetchAdminConfigs } = useContext(
    AdminConfigContext,
  );
  const {
    updateAdminConfig,
  } = AdminConfigService.useUpdateAdminConfig();

  const fetchAdminWhenDone = useCallback(
    async () => await fetchAdminConfigs(),
    [],
  );
  useEffect(() => {
    fetchAdminWhenDone();
  }, []);
  useUpdateEffect(() => {
    // console.log('ADMIN adminConfigsData : ', adminConfigsData);
    setState({
      columns: state.columns,
      data: adminConfigsData,
    });
  }, [adminConfigsData]);

  return (
    <React.Fragment>
      <h1>Configuration</h1>
      <Container maxWidth="md">
        <MaterialTable
          icons={getMaterialTableIcons()}
          title=""
          columns={state.columns}
          data={state.data}
          options={{ actionsColumnIndex: 1 }}
          editable={{
            // onRowAdd: (newData) =>
            //   new Promise(async (resolve, reject) => {
            //     if (await createAdminConfig(newData)) {
            //       resolve();
            //       setState((prevState) => {
            //         const data = [...prevState.data];
            //         data.push(newData);
            //         return { ...prevState, data };
            //       });
            //     } else reject();
            //   }),
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                if (!newData.value || newData.value.trim() === '') {
                  reject();
                  return;
                }
                if (await updateAdminConfig(newData)) {
                  resolve();
                  if (oldData) {
                    setState((prevState) => {
                      const data = [...prevState.data];
                      data[data.indexOf(oldData)] = newData;
                      return { ...prevState, data };
                    });
                  }
                } else reject();
              }),
            // onRowDelete: (oldData) =>
            //   new Promise(async (resolve, reject) => {
            //     if (await deleteAdminConfig(oldData)) {
            //       resolve();
            //       setState((prevState) => {
            //         const data = [...prevState.data];
            //         data.splice(data.indexOf(oldData), 1);
            //         return { ...prevState, data };
            //       });
            //     } else reject();
            //   }),
          }}
        />
      </Container>
    </React.Fragment>
  );
};

export default Config;
