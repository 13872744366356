import { useReducer } from 'react';

import { uniqueStrings } from '../utils/unique';
import FilterModel from '../models/FilterModel';
import { SelectedChoiceI } from '../contexts/MapFilterDataContext';
// check if can use objectWithStringKeyIin other file
declare type objectWithStringKeyI = { [key: string]: any };

const selectedChoiceReducer = (
  state: { [key: string]: any },
  action: {
    type: string;
    payload: {
      kind?: string;
      name?: string;
      filterModel?: any;
      autoToggleMore?: Function;
      setSelectAllOfSectionNames?: Function;
      newSelectedChoice?: SelectedChoiceI // for SELECT_FROM_PRESET only
    };
  },
) => {
  const { kind = '', name, filterModel } = action.payload;
  let newValues;
  let toReturn;
  const sectionName = FilterModel.getSectionNameOfKind(kind);
  // consider moving getExtraAndNamesForUnselect to outside
  const [
    extra,
    names,
    shouldAddBankingAgentsToSetSelectAllLater,
  ] = getExtraAndNames(state, action, sectionName);
  switch (action.type) {
    case 'SELECT_FROM_PRESET':
      const { newSelectedChoice, setSelectAllOfSectionNames } = action.payload as { newSelectedChoice: SelectedChoiceI, setSelectAllOfSectionNames: Function }
      if (newSelectedChoice.channel.includes('bankingAgent')) {
        setSelectAllOfSectionNames(['bankingAgents'], true)
      }
      return newSelectedChoice
    case 'SELECT':
      newValues = uniqueStrings([...state[kind], name]);
      handleSetSelectAllSideEffect(
        action,
        sectionName,
        shouldAddBankingAgentsToSetSelectAllLater,
        newValues,
      );
      return { ...state, [kind]: newValues, ...extra };
    case 'SELECT_ALL':
      handleSetSelectAllSideEffect(
        action,
        sectionName,
        shouldAddBankingAgentsToSetSelectAllLater,
      );
      return {
        ...state,
        [kind]: uniqueStrings([...state[kind], ...names]),
        ...extra,
      };
    case 'UNSELECT':
      // TODO: make the list unique
      newValues = state[kind].filter((n: string) => n !== name);
      toReturn = {
        ...state,
        [kind]: newValues,
        ...extra,
      };
      /* unselect banking agent in channel as well*/
      if (newValues.length === 0 && kind === 'bankingAgent') {
        // unselect bankingAgent of the channels
        toReturn.channel = state.channel.filter(
          (n: string) => n !== 'bankingAgent',
        );
      }
      handleSetSelectAllSideEffect(
        action,
        sectionName,
        shouldAddBankingAgentsToSetSelectAllLater,
        newValues,
      );
      return toReturn;
    case 'UNSELECT_ALL':
      toReturn = {
        ...state,
        [kind]: [],
        ...extra,
      };
      /* unselect banking agent in channel as well*/
      if (kind === 'bankingAgent') {
        // unselect bankingAgent of the channels
        toReturn.channel = state.channel.filter(
          (n: string) => n !== 'bankingAgent',
        );
      }
      handleSetSelectAllSideEffect(
        action,
        sectionName,
        shouldAddBankingAgentsToSetSelectAllLater,
      );
      return toReturn;
    default:
      return state;
  }
};

export default selectedChoiceReducer;


const handleSetSelectAllSideEffect = (
  action: objectWithStringKeyI,
  sectionName: string,
  shouldAddBankingAgentsToSetSelectAllLater: boolean,
  newValues?: Array<string>,
) => {
  const {
    kind = '',
    name,
    filterModel,
    autoToggleMore,
    setSelectAllOfSectionNames,
  } = action.payload;
  if (!setSelectAllOfSectionNames)
    throw Error('need setSelectAllOfSectionNames');
  let toSet;
  switch (action.type) {
    case 'SELECT':
      toSet = [] as Array<string>;
      if (
        newValues &&
        newValues.length ===
        filterModel.getChoicesLengthOfSectionName(sectionName)
      ) {
        toSet = toSet.concat(sectionName);
      }
      if (shouldAddBankingAgentsToSetSelectAllLater) {
        toSet = toSet.concat('bankingAgents');
      }
      setSelectAllOfSectionNames(toSet, true);
      break;
    case 'SELECT_ALL':
      if (shouldAddBankingAgentsToSetSelectAllLater) {
        setSelectAllOfSectionNames(
          ['bankingAgents', sectionName],
          true,
        );
      }
      break;
    case 'UNSELECT':
    case 'UNSELECT_ALL':
      toSet = [sectionName];
      if (shouldAddBankingAgentsToSetSelectAllLater) {
        toSet = toSet.concat('bankingAgents');
      }
      setSelectAllOfSectionNames(toSet, false);
      break;
    default:
      break;
  }
};

const getExtraAndNames = (
  state: objectWithStringKeyI,
  action: objectWithStringKeyI,
  sectionName: string,
) => {
  const fnToCall = action.type.includes('UNSELECT')
    ? getExtraAndNamesForUnselect
    : getExtraAndNamesForSelect;
  return fnToCall(state, action, sectionName);
};

const getExtraAndNamesForUnselect = (
  state: objectWithStringKeyI,
  action: objectWithStringKeyI,
  sectionName: string,
) => {
  const { kind = '', name, filterModel } = action.payload;
  let extra = {};
  let names;
  let shouldAddBankingAgentsToSetSelectAllLater = false;
  if (action.type.includes('_ALL')) {
    names = filterModel?.getChoicesNamesOfSectionName(sectionName);
  }
  if (
    kind === 'channel' &&
    (name === 'bankingAgent' ||
      (names && names.includes('bankingAgent')))
  ) {
    // auto unselect all
    extra = {
      bankingAgent: [],
    };
    shouldAddBankingAgentsToSetSelectAllLater = true;
  } else if (
    kind === 'bankingAgent' &&
    state.channel.includes('bankingAgent') &&
    state.bankingAgent.length === 1
  ) {
    // auto unselect the backing agent if kind is banking agent
    extra = {
      channel: state.channel.filter(
        (c: string) => c !== 'bankingAgent',
      ),
    };
  }
  return [extra, names, shouldAddBankingAgentsToSetSelectAllLater];
};
const getExtraAndNamesForSelect = (
  state: objectWithStringKeyI,
  action: objectWithStringKeyI,
  sectionName: string,
) => {
  const {
    kind = '',
    name,
    filterModel,
    autoToggleMore,
  } = action.payload;
  let extra = {};
  let names;
  let shouldAddBankingAgentsToSetSelectAllLater = false;
  if (action.type.includes('_ALL')) {
    names = filterModel?.getChoicesNamesOfSectionName(sectionName);
  }
  if (
    kind === 'channel' &&
    (name === 'bankingAgent' ||
      (names && names.includes('bankingAgent')))
  ) {
    // autoselect all
    extra = {
      bankingAgent: (state.bankingAgent || []).concat(
        filterModel.bankingAgentsNames,
      ),
    };
    // auto toggle up
    if (autoToggleMore) autoToggleMore();
    shouldAddBankingAgentsToSetSelectAllLater = true;
  } else if (
    kind === 'bankingAgent' &&
    (!state.channel || !state.channel.includes('bankingAgent'))
  ) {
    // auto select the backing agent if kind is banking agent
    extra = {
      channel: (state.channel || []).concat('bankingAgent'),
    };
  }

  return [extra, names, shouldAddBankingAgentsToSetSelectAllLater];
};
