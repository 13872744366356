import React, { createContext, useEffect, useState } from 'react';
export const ScreenModeContext = createContext({
  screenMode: '',
  setScreenMode: (data: string) => {},
});
const ScreenModeContextProvider = (props: any) => {
  const [screenMode, setScreenMode] = useState('');
  return (
    <ScreenModeContext.Provider
      value={{
        screenMode,
        setScreenMode,
      }}
    >
      {props.children}
    </ScreenModeContext.Provider>
  );
};
export default ScreenModeContextProvider;
