import { useContext } from 'react';

import initApiInstance from '../initApiInstance';
import { LoadingContext } from '../../contexts/LoadingContext';
import { AdminAuthContext } from '../../contexts/AdminAuthContext';

import AdminFilterModel from '../../models/AdminFilterModel';

const useFetchAdminFilterSummary = () => {
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const fetchAdminFilterSummary = async () => {
    return new Promise<any>(async (resolve) => {
      handleSetServiceLoading('useFetchAdminFilterSummary', true);
      const data = await apiInstance
        .get(`admin/filters/summary`, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        })
        .then((res) => {
          console.debug('GET admin/filters/summary :', res);
          return res.data;
        })
        .catch((err) => false);
      handleSetServiceLoading('useFetchAdminFilterSummary', false);
      resolve(data);
    });
  };
  return { fetchAdminFilterSummary };
};

const useCreateAdminFilter = () => {
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const createAdminFilter = async (data: AdminFilterModel) => {
    return new Promise<boolean>(async (resolve) => {
      handleSetServiceLoading('useCreateAdminFilter', true);
      const success = await apiInstance
        .post(
          `admin/filters`,
          `ordering=${data.ordering}` +
            `&filterName=${data.filterName}` +
            `&filterType=${data.filterType}` +
            `&labelEn=${data.labelEn}` +
            `&labelTh=${data.labelTh}` +
            `&shortLabelEn=${data.shortLabelEn}` +
            `&shortLabelTh=${data.shortLabelTh}` +
            `&buttonIconId=${data.buttonIconId}` +
            `&pinIconId=${data.pinIconId}`,
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          },
        )
        .then((res) => {
          console.debug('POST admin/filters :', res);
          return res.status === 201;
        })
        .catch((err) => false);
      handleSetServiceLoading('useCreateAdminFilter', false);
      resolve(success);
    });
  };
  return { createAdminFilter };
};

const useUpdateAdminFilter = () => {
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const updateAdminFilter = async (data: AdminFilterModel) => {
    return new Promise<boolean>(async (resolve) => {
      handleSetServiceLoading('useUpdateAdminFilter', true);
      const success = await apiInstance
        .put(
          `admin/filters/${encodeURIComponent(data.filterName)}/${
            data.filterType
          }`,
          `ordering=${data.ordering}` +
            `&filterName=${data.filterName}` +
            `&filterType=${data.filterType}` +
            `&labelEn=${data.labelEn}` +
            `&labelTh=${data.labelTh}` +
            `&shortLabelEn=${data.shortLabelEn}` +
            `&shortLabelTh=${data.shortLabelTh}` +
            `&buttonIconId=${data.buttonIconId}` +
            `&pinIconId=${data.pinIconId}`,
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          },
        )
        .then((res) => {
          console.debug('PUT admin/filters :', res);
          return res.status === 200;
        })
        .catch((err) => false);
      handleSetServiceLoading('useUpdateAdminFilter', false);
      resolve(success);
    });
  };
  return { updateAdminFilter };
};

const useDeleteAdminFilter = () => {
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const deleteAdminFilter = async (data: AdminFilterModel) => {
    return new Promise<boolean>(async (resolve) => {
      handleSetServiceLoading('useDeleteAdminFilter', true);
      const success = await apiInstance
        .delete(
          `admin/filters/${encodeURIComponent(data.filterName)}/${
            data.filterType
          }`,
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          },
        )
        .then((res) => {
          console.debug('DELETE admin/filters :', res);
          return res.status === 200;
        })
        .catch((err) => false);
      handleSetServiceLoading('useDeleteAdminFilter', false);
      resolve(success);
    });
  };
  return { deleteAdminFilter };
};

export default {
  useFetchAdminFilterSummary,
  useCreateAdminFilter,
  useUpdateAdminFilter,
  useDeleteAdminFilter,
};
