interface DataPoint {
  lat: number;
  lng: number;
}

const MAX_ZOOM_ALLOWED = 16;
const MIN_ZOOM_ALLOWED = 7;

/**
 * assuming we have google loaded
 * @param points
 */
const computeAverageDistance = (
  points: DataPoint[],
  zoom?: number,
) => {
  const total = points.reduce((cur, point, index) => {
    const newDist =
      index + 1 < points.length
        ? google.maps.geometry.spherical.computeDistanceBetween(
          new google.maps.LatLng(
            points[index].lat,
            points[index].lng,
          ),
          new google.maps.LatLng(
            points[index + 1].lat,
            points[index + 1].lng,
          ),
        )
        : 0;
    return cur + newDist;
  }, 0);
  return total / points.length;
};

const distanceInPx = (
  map: any,
  point1: DataPoint,
  point2: DataPoint,
) => {
  const p1 = map
    .getProjection()
    .fromLatLngToPoint(
      new google.maps.LatLng({ lat: point1.lat, lng: point1.lng }),
    );
  const p2 = map
    .getProjection()
    .fromLatLngToPoint(
      new google.maps.LatLng({ lat: point2.lat, lng: point2.lng }),
    );

  const pixelSize = Math.pow(2, -map.getZoom());

  const d =
    Math.sqrt(
      (p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y),
    ) / pixelSize;
  // console.debug('calculate dis : ', p1, p2, d);
  return d;
};

const findCentroid = (points: DataPoint[]) => {
  if (!points.length) return;
  let avrLat = 0;
  let avrLng = 0;
  points.forEach((dp: any) => {
    avrLat = avrLat + dp.lat;
    avrLng = avrLng + dp.lng;
  });
  const a = avrLat / points.length;
  const b = avrLng / points.length;
  console.log('lat : lng : ', a, b);
  return { lat: a, lng: b };
};

const computeAverageDistanceInPx = (
  map: any,
  points: DataPoint[],
) => {
  const total = points.reduce((cur, point, index) => {
    const newDist =
      index + 1 < points.length
        ? distanceInPx(map, points[index], points[index + 1])
        : 0;
    return cur + newDist;
  }, 0);
  return total / points.length;
};
export const computeMinDistanceInPix = (
  map: any,
  points: DataPoint[],
) => {
  const total = points.length
    ? points.reduce((cur, point, index) => {
      const newDist =
        index + 1 < points.length
          ? distanceInPx(map, points[index], points[index + 1])
          : 0;
      if (typeof newDist == 'number' && newDist > 1) {
        if (newDist < cur) {
          cur = newDist;
        }
      }
      return cur;
    }, 1000000)
    : 0;
  return total;
};
/**
 * if very zoomed in (>=15) show all
 * else if average distance is very close (< 155) (not sure the unit) then random (arbitary chance number here)
 * else (probably not that close) show all
 * @param map
 * @param points
 */
const getPointsToShow = (
  map: any,
  points: DataPoint[],
  ignoreBounds = false,
): { inbound: DataPoint[]; avgDist: number } => {
  const bounds = map.getBounds();
  // only count those in bound
  const inbound = ignoreBounds
    ? points
    : points.filter((dp: { lat: number; lng: number }) =>
      bounds.contains(new google.maps.LatLng(dp.lat, dp.lng)),
    );
  const avgDist = computeMinDistanceInPix(map, inbound);
  const zoom = map.getZoom();
  const chance = 1;
  console.debug(
    'getPointsToShow: chance',
    chance,
    ' avgDist ',
    avgDist,
    ' zoom ',
    zoom,
  );
  return { inbound, avgDist };
};

/**
 * TODO: check if needs ignoreBounds
 * @param map
 * @param points
 */
const autoAdjustZoomAndCenter = (
  map: any,
  points: DataPoint[],
  goalNumPoints: number,
) => {
  console.info(
    `*** finding the appropriate zoom. goalNumPoints=${goalNumPoints} MIN_ZOOM_ALLOWED=${MIN_ZOOM_ALLOWED} MAX_ZOOM_ALLOWED=${MAX_ZOOM_ALLOWED}`,
  );
  let toZoomOut = false; // keep zooming in
  let numInbound = 0,
    zoom = 0,
    inbound = [] as DataPoint[];
  const computeNumInBounds = () => {
    const bounds = map.getBounds();
    inbound = points.filter((dp: { lat: number; lng: number }) =>
      bounds.contains(new google.maps.LatLng(dp.lat, dp.lng)),
    );
    let nonDupInbound = [] as DataPoint[];
    inbound.forEach((dp: DataPoint) => {
      if (
        nonDupInbound.filter(
          (nonDupDp: DataPoint) =>
            dp.lat === nonDupDp.lat && dp.lat === nonDupDp.lat,
        ).length === 0
      )
        nonDupInbound.push(dp);
    });
    numInbound = nonDupInbound.length;
    // get current zoom level
    zoom = map.getZoom();
    if (numInbound === 0 && points.length !== 0) {
      toZoomOut = true;
    }
    console.info(
      `current numInBound=${numInbound}. zoom=${zoom}. toZoomOut=${toZoomOut}. goalNumPoints=${goalNumPoints}. ${toZoomOut ? `points ${points.length}` : ''
      }`,
    );
  };
  computeNumInBounds();

  // keep zooming out
  if (toZoomOut) {
    /* keep zooming out only when ... */
    while (
      numInbound < goalNumPoints &&
      numInbound !== points.length &&
      zoom > MIN_ZOOM_ALLOWED
    ) {
      zoom -= 1;
      // decrease zoom level
      map.setZoom(zoom);
      // recalculate numInBounds
      computeNumInBounds();
    }
  } else {
    /* keep zooming in when ... */
    while (numInbound > goalNumPoints && zoom < MAX_ZOOM_ALLOWED) {
      zoom += 1;
      // increase zoom level
      map.setZoom(zoom);
      // recalculate numInBounds
      computeNumInBounds();
    }
  }

  // set center at the end of zoom searching
  if (inbound.length) {
    console.log('inbound . length : ', inbound.length);
    const centroid = findCentroid(inbound);
    console.info(
      `*** set center to the centroid of the points -> ${centroid}`,
    );
    // map.setCenter(centroid);
  }
};

export default {
  computeAverageDistance,
  computeAverageDistanceInPx,
  // getRandomPointsToShow,
  getPointsToShow,
  autoAdjustZoomAndCenter,
  findCentroid,
};
