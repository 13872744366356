import { DEFAULT_LANG } from '../contexts/LangContext';
import { FilterData } from '../mock/filtersData';
import { DEFAULT_SELECTED_CHOICE } from '../contexts/MapFilterDataContext';

const langToLabelKey = {
  th: {
    short: 'shortLabelTh',
    long: 'labelTh',
  },
  en: {
    short: 'shortLabelEn',
    long: 'labelEn',
  },
} as { [key: string]: any };

/**
 * format the new data to be in the old format
 * filter type ex: service,
 * @returns object with key of sectionName and value is an array
 */
const getGroupedRawDataBySectionNames = (
  oriRawData: null | { [key: string]: any },
): null | { [key: string]: any[] } => {
  const groupedRawData: { [key: string]: any } = {};
  if (!oriRawData) return null;
  oriRawData.forEach((data: any) => {
    if (data.filterType) {
      const ft = data.filterType;
      // if not already in
      if (!(ft in groupedRawData)) {
        groupedRawData[ft] = [];
      }
      groupedRawData[ft] = groupedRawData[ft].concat(data);
    }
  });

  const addSToKey = Object.keys(groupedRawData).reduce(
    (acc: any, filterType: string) => ({
      ...acc,
      [FilterModel.getSectionNameOfKind(filterType)]: groupedRawData[
        filterType
      ],
    }),
    {},
  );

  return addSToKey;
};

const getFilterLabelKeyForLang = (lang: string = DEFAULT_LANG) => {
  if (lang === 'en') return 'labelEn';
  else if (lang === 'th') return 'labelTh';
  return 'labelEn';
};

class FilterModel {
  oriRawData: { [key: string]: any } | null = null;
  rawData: { [key: string]: any[] } | null = null;
  lang = DEFAULT_LANG;
  nameLogosMap: {
    [key: string]: { pinLogoUrl: string; buttonLogoUrl: string };
  } = {};
  nameLabelMap: { [key: string]: string } = {};
  allMenuSections: {
    name: string;
    choices: { longLabel: any; label: any }[];
  }[] = [];
  preset: { [key: string]: any } | null = null;

  sectionsNames = ['services', 'channels', 'bankingAgents'];

  constructor(
    oriRawData: { [key: string]: any } | null,
    lang: string,
  ) {
    this.oriRawData = oriRawData;
    this.rawData = getGroupedRawDataBySectionNames(oriRawData);
    this.lang = lang;
    this.allMenuSections = this.getAllMenuSections();
    this.nameLogosMap = this.getNameLogosMap();
    this.nameLabelMap = this.getNameLabelMap();
  }

  setPreset(preset: { [key: string]: any }) {
    this.preset = preset;
  }

  setLang(newLang: string) {
    this.lang = newLang;
  }

  formatLabelOfRawChoices(rawChoices: Array<{ [key: string]: any }>) {
    const result = rawChoices.map((s: { [key: string]: any }) => ({
      ...s,
      longLabel: s[langToLabelKey[this.lang]['long']] || '-',
      label: s[langToLabelKey[this.lang]['short']] || '-', // short
      disabledFromPreset: !this.preset
        ? false
        : !(s.filterName in this.preset), // if not in then disabled
    }));
    return result;
  }

  /**
   * only works if preset is in the format of { <filterName>: <}
   */
  getSelectedChoiceFromPreset() {
    if (!this.preset) throw Error(`doesn't have preset`);
    const preset = this.preset as { [key: string]: string };
    return Object.keys(preset).reduce(
      (acc: { [key: string]: string[] }, key: string) => {
        const type = preset[key];
        return { ...acc, [type]: acc[type].concat(key) };
      },
      DEFAULT_SELECTED_CHOICE,
    );
  }

  updateAllMenuSections() {
    this.allMenuSections = this.getAllMenuSections();
  }

  /**
   * this is used in MainFilterPanel
   */
  getAllMenuSections() {
    return this.sectionsNames.map((name: string) => ({
      name,
      choices: this.rawData
        ? this.formatLabelOfRawChoices(this.rawData[name])
        : [],
    }));
  }

  /**
   * @param name  ex: withdrawal
   * @param kind
   */
  getImageUrlOf(name: string, imageKind: 'button' | 'pin') {
    const first: { [key: string]: string } = this.nameLogosMap[name];
    if (!first) return;
    return first[`${imageKind}LogoUrl`];
  }

  getNameLogosMap() {
    return this.oriRawData?.reduce(
      (acc: any, row: any) => ({
        ...acc,
        [row.filterName]: {
          pinLogoUrl: row.pinLogoUrl,
          buttonLogoUrl: row.buttonLogoUrl,
        },
      }),
      {},
    );
  }

  /**
   * long label
   */
  getLabelOfName(name: string) {
    return this.nameLabelMap[name];
  }

  /**
   * selected lang only
   */
  getNameLabelMap() {
    return this.oriRawData?.reduce(
      (acc: any, row: any) => ({
        ...acc,
        [row.filterName]: row[getFilterLabelKeyForLang(this.lang)],
      }),
      {},
    );
  }

  /**
   * no bankingAgents
   */
  get lessMenuSections() {
    return this.allMenuSections.filter(
      (section: { [key: string]: any }) =>
        section.name !== 'bankingAgents',
    );
  }

  /**
   * all the banking agents (the children) names excluding the preset
   */
  get bankingAgentsNames() {
    const names = this.rawData
      ? this.rawData.bankingAgents.map((ba: any) => ba.filterName)
      : [];

    return this.getNamesNotInPreset(names);
  }

  getNamesNotInPreset(names: string[]) {
    if (!this.preset || names.length === 0) return names;
    return names.filter((name) =>
      this.preset !== null ? name in this.preset : true,
    );
  }

  /**
   * all excluding what's in the preset
   * @param sectionName
   */
  getChoicesNamesOfSectionName(sectionName: string) {
    const section = this.allMenuSections.find(
      (sn: any) => sn.name === sectionName,
    );
    const names =
      section?.choices.map((c: any) => c.filterName) || [];
    return this.getNamesNotInPreset(names);
  }

  getChoicesLengthOfSectionName(sectionName: string) {
    return this.getChoicesNamesOfSectionName(sectionName).length;
  }

  static getKindForSectionNames(sectionName: string) {
    return sectionName.substring(0, sectionName.length - 1);
  }

  static getSectionNameOfKind(kind: string) {
    return `${kind}s`;
  }

  static formatSelectedChoiceToHaveSectionNamesAsKeys(selectedChoice: {
    [key: string]: string[];
  }) {
    return Object.keys(selectedChoice).reduce(
      (acc: any, kind: string) => ({
        ...acc,
        [FilterModel.getSectionNameOfKind(kind)]: selectedChoice[
          kind
        ],
      }),
      {},
    );
  }
}
export default FilterModel;
