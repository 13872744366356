import React, { createContext, useEffect } from 'react';

import useFetchAllMenuSections from '../services/useFetchAllMenuSections';
import useFetchPreset from '../services/useFetchPreset';
import { useParams } from 'react-router-dom';

export const FilterContext = createContext({
  filterModel: null as any,
  fetchAllMenuSections: () => new Promise<void>((resolve) => {}),
  fetchAllMenuSectionsWithPreset: () =>
    new Promise<void>((resolve) => {}),
});

const FilterContextProvider = (props: any) => {
  const {
    filterModel,
    fetchData: fetchAllMenuSections,
  } = useFetchAllMenuSections();
  const { data: preset, fetchData: fetchPreset } = useFetchPreset();
  const { presetUrl } = useParams();
  const hasToFetchPreset = !!presetUrl;

  /**
   * TODO: test if done without premise and it would work
   */
  const fetchAllMenuSectionsWithPreset = async () => {
    await fetchAllMenuSections();
    if (hasToFetchPreset) {
      await fetchPreset(presetUrl, filterModel);
    }
  };

  useEffect(() => {
    if (preset) {
      console.debug(
        'filterModel is updated so updating its preset',
        preset,
      );
      filterModel.setPreset(preset); // this should mutate the filterModel
    }
  }, [filterModel, preset]);

  useEffect(() => {
    if (filterModel.preset) {
      console.debug(
        'filterModel menuSections call update all menu sections',
      );
      filterModel.updateAllMenuSections();
    }
  }, [filterModel.preset]);

  return (
    <FilterContext.Provider
      value={{
        filterModel,
        fetchAllMenuSections,
        fetchAllMenuSectionsWithPreset,
      }}
    >
      {props.children}
    </FilterContext.Provider>
  );
};

export default FilterContextProvider;
