import { useEffect, useState, useContext, useMemo } from 'react';

import { LangContext } from '../contexts/LangContext';
import { LoadingContext } from '../contexts/LoadingContext';
import mockedFiltersData from '../mock/filtersData';
import FilterModel from '../models/FilterModel';
import initApiInstance from './initApiInstance';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';
/**
 * TODO: incorporate the idea of generics
 * TODO: change to fetch data from backend
 */
const useFetchAllMenuSections = () => {
  const { lang } = useContext(LangContext);
  const { token } = useContext(AuthContext);
  const [filtersData, setFiltersData] = useState<any>(null);
  const apiInstance = initApiInstance();

  const { handleSetServiceLoading } = useContext(LoadingContext);

  useEffect(() => {
    handleSetServiceLoading('useFetchAllMenuSections', true);
  }, []);

  const fetchData = async () => {
    /**
     * every time that fetch all menu sections will fetch preset as well
     */

    new Promise(async (resolve) => {
      handleSetServiceLoading('useFetchAllMenuSections', true);
      const data = await apiInstance
        .get(`filters`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          console.debug(`filters : `, res.data);
          return res.data;
        });

      setFiltersData(data);
      handleSetServiceLoading('useFetchAllMenuSections', false);
      resolve(data);
    });
  };

  /**
   * update filterModel if data or language changed
   */
  const filterModel = useMemo(() => {
    const fModel = new FilterModel(filtersData, lang);
    return fModel;
  }, [filtersData, lang]);

  // Return the width so we can use it in our components
  return { filterModel, fetchData };
};

export default useFetchAllMenuSections;
