import { useContext } from 'react';

import initApiInstance from '../initApiInstance';
import { LoadingContext } from '../../contexts/LoadingContext';
import { AdminAuthContext } from '../../contexts/AdminAuthContext';

/**
 * TODO:
 */
const useFetchUploadSignedUrl = () => {
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const fetchUploadSignedUrl = async (
    service: string,
    filename: string,
    contentType: string,
  ) => {
    return new Promise<string>(async (resolve) => {
      handleSetServiceLoading('useFetchUploadSignedUrl', true);
      const result: string = await apiInstance
        .get(
          `admin/getSignedUrl?service=${service}&filename=${filename}${
            contentType ? '&contentType=' + contentType : ''
          }`,
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          },
        )
        .then((res) => {
          console.debug(`admin/getSignedUrl : `, res);
          return res.data;
        });

      handleSetServiceLoading('useFetchUploadSignedUrl', false);
      resolve(result);
    });
  };

  return { fetchUploadSignedUrl };
};

const usePutFileToSignedUrl = () => {
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);

  /**
   *  ##################################################
   *  ##########          SETUP CORS          ##########
   *  ##################################################
   *  2. Setup CORS (Cross Origin Resource Sharing)
   *  In order to upload files from some website you need to setup CORS so that bucket accept
   *  requests from your domain. To do that, we need to upload configuration file with allowed
   *  domains using gsutil (it cannot be done through web interface).
   *  This is sample of json file to setup cors:
   *    [
   *        {
   *          "origin": ["http://adventures-on-gcp.appspot.com", "http://localhost:8080"],
   *          "responseHeader": ["Content-Type"],
   *          "method": ["GET", "HEAD", "DELETE", "PUT", "POST"],
   *          "maxAgeSeconds": 3600
   *        }
   *    ]
   *  command is:
   *    gsutil cors set gcs_cors.json gs://<BUCKET-NAME>
   *  Of course setting localhost as allowed domain is only good for development / testing purpose.
   *  see more: https://www.the-swamp.info/blog/uploading-files-google-cloud-storage/
   */
  const putFileToSignedUrl = async (
    signedUrl: string,
    file: File,
  ) => {
    return new Promise<boolean>(async (resolve) => {
      handleSetServiceLoading('usePutFileToSignedUrl', true);
      const result = await apiInstance
        .put(signedUrl, file, {
          headers: {
            'Content-Type': file.type || 'application/octet-stream',
          },
        })
        .then((res) => {
          console.debug(`putFileToSignedUrl : `, res);
          return res.status === 200;
        });

      handleSetServiceLoading('usePutFileToSignedUrl', false);
      resolve(result);
    });
  };

  return { putFileToSignedUrl };
};

const usePostLocatorDataFromGcs = () => {
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const postLocatorDataFromGcs = async (filepath: string) => {
    return new Promise<boolean>(async (resolve) => {
      handleSetServiceLoading('usePostLocatorDataFromGcs', true);
      const result: boolean = await apiInstance
        .post(`admin/processDataFile`, `filepath=${filepath}`, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        })
        .then((res) => {
          console.debug(`POST admin/processDataFile : `, res);
          return res.status === 201;
        });

      handleSetServiceLoading('usePostLocatorDataFromGcs', false);
      resolve(result);
    });
  };

  return { postLocatorDataFromGcs };
};

const useFetchSuccessUploadHistory = () => {
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const fetchSuccessUploadHistory = async () => {
    return new Promise<
      { id: number; uploadDate: Date; totalRecords: number }[]
    >(async (resolve) => {
      handleSetServiceLoading('useFetchSuccessUploadHistory', true);
      const result: {
        id: number;
        uploadDate: Date;
        totalRecords: number;
      }[] = await apiInstance
        .get(`admin/uploadHistory`, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        })
        .then((res) => {
          console.debug(`GET admin/uploadHistory : `, res);
          return res.data;
        });

      handleSetServiceLoading('useFetchSuccessUploadHistory', false);
      resolve(result);
    });
  };

  return { fetchSuccessUploadHistory };
};

export default {
  useFetchUploadSignedUrl,
  usePutFileToSignedUrl,
  usePostLocatorDataFromGcs,
  useFetchSuccessUploadHistory,
};
