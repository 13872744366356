import React, { useContext, useMemo } from 'react';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import styled from 'styled-components/macro';

import { MultiDrawerStateContext } from '../contexts/MultiDrawerStateContext';
import { LangContext } from '../contexts/LangContext';
// https://github.com/FormidableLabs/react-swipeable
import { Swipeable } from 'react-swipeable';
import { ScreenModeContext } from '../contexts/ScreenModeContext';

interface HeightsProps {
  collapsed?: string;
  normal?: string;
  more?: string;
}
interface BottomDrawerProps {
  drawerState?: string;
  onDrawerStateChange?: Function;
  drawerName: string;
  heights?: HeightsProps;
}

const BottomDrawer: React.FC<BottomDrawerProps> = (props) => {
  const { currentLangData } = useContext(LangContext);
  const { drawerName, heights } = props;
  const {
    drawerStateMap,
    clickHandleHandler,
    swipeHandlers,
  } = useContext(MultiDrawerStateContext);
  const { screenMode } = useContext(ScreenModeContext);

  const drawerState = useMemo(() => drawerStateMap[drawerName], [
    drawerStateMap,
    drawerName,
  ]);
  const renderDrawerIcon = () => {
    if (screenMode != 'desktop') {
      if (['NORMAL'].includes(drawerState)) {
        return (
          <SIconWrapper>
            <SArrowWrapper>
              <KeyboardArrowUpIcon />
            </SArrowWrapper>
            <STextWrapper>{currentLangData.showAll}</STextWrapper>
          </SIconWrapper>
        );
      } else if (
        drawerStateMap.InfoPanel === 'COLLAPSED' &&
        drawerStateMap.MainFilterPanel === 'COLLAPSED'
      ) {
        return (
          <SIconWrapper>
            <SArrowWrapper>
              <KeyboardArrowUpIcon />
            </SArrowWrapper>
            <STextWrapper>{currentLangData.showMore}</STextWrapper>
          </SIconWrapper>
        );
      } else if (drawerState === 'MORE') {
        return (
          <SIconWrapper>
            <SArrowWrapper>
              <KeyboardArrowDownIcon />
            </SArrowWrapper>
            <STextWrapper>{currentLangData.collapse}</STextWrapper>
          </SIconWrapper>
        );
      }
    }
  };
  const bottomDrawerClass = drawerState.toLowerCase();
  return (
    <SBottomDrawer
      heights={heights}
      className={`${bottomDrawerClass} ${screenMode}`}
    >
      {screenMode !== 'desktop' && (
        <SDrawerHandleWrapper
          {...swipeHandlers(drawerName)}
          {...{
            preventDefaultTouchmoveEvent: true,
            trackMouse: true,
          }}
        >
          {
            <SDrawerIconWrapper
              onClick={(event: any) => {
                clickHandleHandler(drawerName, event);
              }}
            >
              {renderDrawerIcon()}
            </SDrawerIconWrapper>
          }
        </SDrawerHandleWrapper>
      )}
      <SContentWrapper className={bottomDrawerClass}>
        {props.children}
      </SContentWrapper>
    </SBottomDrawer>
  );
};

export default BottomDrawer;

const SArrowWrapper = styled.div`
  & > .MuiSvgIcon-root {
    font-size: 2rem;
  }
`;

const SContentWrapper = styled.div`
  &.collapsed {
    & > * {
      margin-top: 2.2vh;
    }
  }
`;

const SIconWrapper = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
}
`;

const STextWrapper = styled.div`
  font-size: 10px;
  position: absolute;
  top: 1rem;
`;

const SDrawerIconWrapper = styled.div`
  color: ${(props) => props.theme.colors.grey.medium}; // or #808080
  margin-top: 3px;
  flex: 1;
  justify-content: center;
  display: flex;
`;
const SDrawerHandleWrapper = styled(Swipeable)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
`;
const SBottomDrawer = styled.div.attrs((props) => ({
  className: props.className,
}))<{ heights?: HeightsProps }>`
  // TODO: considering putting box shadow in the theme

  position: absolute;
  &.app {
    box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.25);
    width: calc(100% - 3px);
    // TODO @Kwan: double check about the px vs em vs rem vs %
    &.collapsed {
      height: ${(props) =>
        (props.heights && props.heights['collapsed']) || '4.8vh'};
      transition: height 0.2s ease-out; // on the way in
      @media screen and (min-width: 768px) {
        height: 3.8vh;
      }
    }
    &.normal {
      height: ${(props) =>
        (props.heights && props.heights['normal']) || '35vh'};
      transition: height 0.2s ease-out; // on the way in
    }
    &.more {
      height: ${(props) =>
        (props.heights && props.heights['more']) || '70vh'};
      transition: height 0.2s ease-out; // on the way in
      overflow-y: scroll;
    }
    height: 35vh;
    bottom: 0;
    padding: 2px;
  }
  &.desktop {
    &.collapsed {
      display: none;
    }
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding-left: 8px;
    padding-right: 8px;
    width: 26.38vw;
    max-height: 80vh;
    min-height: 50vh;
    overflow: scroll;
    top: 8vh;
    left: 0.7vw;
  }

  background: ${(props) => props.theme.colors.background.primary};
  overflow: hidden;
  border-radius: 10px 10px 10px 10px; // top-left top-right ...
  transition: height 0.2s ease-in; // on the way out
  color: ${(props) => props.theme.colors.text.primary};
`;
