import { useContext } from 'react';

import initApiInstance from '../initApiInstance';
import { LoadingContext } from '../../contexts/LoadingContext';
import { AdminAuthContext } from '../../contexts/AdminAuthContext';

const useCreateAdminPreset = () => {
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const createAdminPreset = async (data: {
    url: string;
    oldUrl?: string;
    service: Array<string>;
    channel: Array<string>;
    bankingAgent: Array<string>;
  }) => {
    return new Promise<boolean>(async (resolve) => {
      handleSetServiceLoading('useCreateAdminPreset', true);
      let body = [`url=${data.url}`];
      data.service.length !== 0 &&
        body.push(`service=${data.service.join(',')}`);
      data.channel.length !== 0 &&
        body.push(`channel=${data.channel.join(',')}`);
      data.bankingAgent.length !== 0 &&
        body.push(`bankingAgent=${data.bankingAgent.join(',')}`);
      const success = await apiInstance
        .post(`admin/presets`, body.join('&'), {
          headers: { Authorization: `Bearer ${jwtToken}` },
        })
        .then((res) => {
          console.debug('POST admin/presets :', res);
          return res.status === 201;
        })
        .catch((err) => false);
      handleSetServiceLoading('useCreateAdminPreset', false);
      resolve(success);
    });
  };
  return { createAdminPreset };
};

const useUpdateAdminPreset = () => {
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const updateAdminPreset = async (data: {
    url: string;
    oldUrl?: string;
    service: Array<string>;
    channel: Array<string>;
    bankingAgent: Array<string>;
  }) => {
    return new Promise<boolean>(async (resolve) => {
      handleSetServiceLoading('useUpdateAdminPreset', true);
      let body = [`url=${data.url}`];
      data.service.length !== 0 &&
        body.push(`service=${data.service.join(',')}`);
      data.channel.length !== 0 &&
        body.push(`channel=${data.channel.join(',')}`);
      data.bankingAgent.length !== 0 &&
        body.push(`bankingAgent=${data.bankingAgent.join(',')}`);
      const success = await apiInstance
        .put(`admin/presets/${data.oldUrl}`, body.join('&'), {
          headers: { Authorization: `Bearer ${jwtToken}` },
        })
        .then((res) => {
          console.debug('PUT admin/presets :', res);
          return res.status === 200;
        })
        .catch((err) => false);
      handleSetServiceLoading('useUpdateAdminPreset', false);
      resolve(success);
    });
  };
  return { updateAdminPreset };
};

const useDeleteAdminPreset = () => {
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const deleteAdminPreset = async (url: string) => {
    return new Promise<boolean>(async (resolve) => {
      handleSetServiceLoading('useDeleteAdminPreset', true);
      const success = await apiInstance
        .delete(`admin/presets/${url}`, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        })
        .then((res) => {
          console.debug('DELETE admin/presets :', res);
          return res.status === 200;
        })
        .catch((err) => false);
      handleSetServiceLoading('useDeleteAdminPreset', false);
      resolve(success);
    });
  };
  return { deleteAdminPreset };
};

export default {
  useCreateAdminPreset,
  useUpdateAdminPreset,
  useDeleteAdminPreset,
};
