import React, { useContext } from 'react';

import MyLocationIcon from '@material-ui/icons/MyLocation';
import styled from 'styled-components/macro';
import { ScreenModeContext } from '../contexts/ScreenModeContext';
interface SearchNearMeProps {
  handleClick: Function;
}
const SearchNearMe: React.FC<SearchNearMeProps> = (props) => {
  const { screenMode } = useContext(ScreenModeContext);
  return (
    <SFloatButton
      onClick={() => props.handleClick()}
      className={`${screenMode} clickable`}
    >
      <MyLocationIcon />
    </SFloatButton>
  );
};

export default SearchNearMe;

const SFloatButton = styled.div`
  border-radius: 50%;
  width: 10vw;
  height: 10vw;
  background: ${(props) => props.theme.colors.palette.primary};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.18) 1px 1px 2px 0px;
  &.desktop {
    width: 4vw;
    height: 4vw;
    & > .MuiSvgIcon-root {
      font-size: 2vw;
    }
  }
  &:active {
    box-shadow: rgba(0, 0, 0, 0.18) 1px 1px 8px 3px;
  }
  & > .MuiSvgIcon-root {
    font-size: 6vw;
  }
`;
