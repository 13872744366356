import React, { createContext, useEffect, useState } from 'react';
import useFetchToken from '../services/useFetchToken';

export const AuthContext = createContext<{ [key: string]: any }>({
  token: '',
  setToken: () => {},
  tokenLoading: true,
  setTokenLoading: () => {},
  tokenAlert: false,
  setTokenAlert: () => {},
});
const AuthContextProvider: React.FC = (props) => {
  const [token, setToken] = useState<string>('');
  const [tokenLoading, setTokenLoading] = useState<boolean>(true);
  const [tokenAlert, setTokenAlert] = useState<boolean>(false);
  useEffect(() => {
    getToken();
  }, []);
  let i = 0;
  const timeout = (delay: number) => {
    return new Promise((res) => setTimeout(res, delay));
  };
  const getToken = async () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const fetchToken = await useFetchToken();
    if (fetchToken.status !== 200 && !token && i < 6) {
      i++;
      await timeout(500);
      getToken();
    } else if (fetchToken.status === 200 && !token) {
      setToken(fetchToken.data);
      setTokenLoading(false);
    }
    if (!token && i > 5) {
      setTokenAlert(true);
      setTokenLoading(false);
    }
  };
  return (
    <AuthContext.Provider value={{ token, tokenLoading, tokenAlert }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
