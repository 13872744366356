import React, {
  createContext,
  useState,
  useLayoutEffect,
  useEffect,
} from 'react';

import AdminUserService from '../services/admin/AdminUserService';
import AdminUserModel from '../models/AdminUserModel';

interface AdminUserContextProviderProps {}
interface AdminUserContextValue {
  adminUsersData: AdminUserModel[];
  fetchAdminUsers: () => Promise<AdminUserModel[]>;
}

export const AdminUserContext = createContext<AdminUserContextValue>({
  adminUsersData: [],
  fetchAdminUsers: () => new Promise<AdminUserModel[]>((r) => {}),
});

const AdminUserContextProvider: React.FC<AdminUserContextProviderProps> = (
  props,
) => {
  const {
    adminUsersData,
    fetchAdminUsers,
  } = AdminUserService.useFetchAdminUsers();

  return (
    <AdminUserContext.Provider
      value={{
        adminUsersData,
        fetchAdminUsers,
      }}
    >
      {props.children}
    </AdminUserContext.Provider>
  );
};

export default AdminUserContextProvider;
