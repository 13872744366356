/*global google*/
import React, {
  useRef,
  useEffect,
  useImperativeHandle,
  useContext,
  useState,
  useMemo,
} from 'react';

import {
  GoogleMap,
  LoadScript,
  Marker,
} from '@react-google-maps/api'; // https://react-google-maps-api-docs.netlify.app/#section-introduction
import { useUpdateEffect } from 'react-use';
import styled from 'styled-components/macro';

import {
  MapFilterDataContext,
  DEFAULT_SELECTED_CHOICE,
} from '../contexts/MapFilterDataContext';
import { MapInfoContext } from '../contexts/MapInfoContext';
import { MultiDrawerStateContext } from '../contexts/MultiDrawerStateContext';
import useCurrentLocation, {
  DEFAULT_COORDS,
} from '../custom-hooks/useCurrentLocation';
import bluePoint from '../images/circle.png';
import mapImage from '../images/map-screenshot.png';
import GreenPin from '../images/pin.png';
import MapHelper from '../helpers/MapHelper';
import { useDebouncedCallback } from 'use-debounce';
import { translateScreenCenterDown } from '../contexts/CurrentLocationContext';
import { InputFocusContext } from '../contexts/InputFocusContext';
import { ScreenModeContext } from '../contexts/ScreenModeContext';
const WAIT_TIME_BEFORE_FETCH = 500;
let searchBox: any;
const onLoaded = (ref: any) => (searchBox = ref);
const onPlacesChanged = () =>
  console.debug('main map onPlacesChanged', searchBox.getPlaces());
interface MainMapProps {
  ref: any;
  currentCoords?: {
    lat: number;
    lng: number;
  };
  zoom?: number;
  useFake?: boolean; // if true will display image instead (so we dont waste the map api call)
  withoutDebouncedFetchDataPoints?: () => void;
  debouncedFetchDataPoints?: () => void;
}

const defaultProps = {
  currentCoords: DEFAULT_COORDS,
  zoom: 15,
};
const containerStyle = {
  width: '100%',
  height: '100%',
};

const MAP_LIBRARIES = ['geometry', 'places'];

const MainMapFunction = (
  {
    currentCoords = defaultProps.currentCoords,
    useFake = false,
    debouncedFetchDataPoints = () => { },
    withoutDebouncedFetchDataPoints = () => { },
  },
  ref: any, //TODO: come back to fix this
) => {
  const { screenMode } = useContext(ScreenModeContext);
  const [map, setMap] = React.useState<null | any>(null);
  const [zoomText, setZoomText] = useState(defaultProps.zoom);
  const { reReadCurrentLocation } = useCurrentLocation();
  const {
    isFocus: showMobileFullSc,
    handleSetIsFocus: setShowMobileFullSc,
  } = useContext(InputFocusContext);
  const [lastFetchedBounds, setLastFetchedBounds] = React.useState<
    null | any
  >(null);
  const {
    collapseAllDrawer,
    handleSetDrawerState,
    collapseDrawer,
  } = useContext(MultiDrawerStateContext);
  const {
    viewingPoint,
    handleSetViewingPoint,
    isShowingDirection,
  } = useContext(MapInfoContext);
  const { isFocus: isInputFocus } = useContext(InputFocusContext);
  const mainMapRef = useRef(null);
  const prevScreenCenter = useRef({ lat: 0, lng: 0 });
  const prevSelectedChoice = useRef(DEFAULT_SELECTED_CHOICE);
  const prevKeyword = useRef('');

  useImperativeHandle(ref, () => {
    return {
      setCenter: setCenterAndFetchData,
      getCenter: getMapCenter, // TODO: consider chanign the getMapCenter's name to getCenter
    };
  });

  // fetch menusections
  const { selectedChoice, dataPoints, keyword } = useContext(
    MapFilterDataContext,
  );

  const [randomDataPoints, setRandomDataPoints] = useState<any[]>([]);

  /**
   * figure out what datapoints to render after datapoints are updated
   */
  useUpdateEffect(() => {
    console.debug('dataPoints is updated', dataPoints);
    if (map) setLastFetchedBounds(map.getBounds());
    randomAndSetRandomDataPoints();
  }, [dataPoints]);

  /**
   * dataPoints + viewingPoint (if viewingPoint is not in dataPoints)
   */
  const toShowDataPoints = useMemo(() => {
    if (!viewingPoint) return randomDataPoints;
    const dataPointsIds = randomDataPoints.map((dp: any) => dp.id);
    let toReturn = randomDataPoints;
    if (!dataPointsIds.includes(viewingPoint.id)) {
      toReturn = toReturn.concat(viewingPoint);
    }
    return toReturn;
  }, [randomDataPoints]);

  /**
   * everytime the random datapoints is updated. change the center of the screen to be of the first point in the datapoints (already sorted by distance)
   */

  /**
   * IMPORTANT
   * for first release, we do not random
   * but after 1st release will consider random
   * if has keyword ignore the boundary
   */
  const randomAndSetRandomDataPoints = () => {
    if (!map) return;
    // when want to random, just change from getPointsToShow to getRandomPointsToShow
    const { inbound: toReturn } = MapHelper.getPointsToShow(
      map,
      dataPoints,
      keyword && keyword !== '', //if has keyword ignore the boundary (ignoreBounds = true)
    );
    setRandomDataPoints(toReturn);
  };

  /**
   * when selected choice change update screen center to be the centroid
   */
  const [accuracy, setAccuracy] = useState(0);
  useEffect(() => {
    getAccuracy();
  });

  const getAccuracy = () => {
    navigator.geolocation.getCurrentPosition((pos: any) => {
      setAccuracy(pos.coords.accuracy);
      console.debug(
        'REREAD location: navigator',
        pos.coords.accuracy,
      );
    });
  };

  useEffect(() => {
    if (prevKeyword.current !== keyword) {
      if (!map) return;
      const centroidKeyword = MapHelper.findCentroid(dataPoints);
      if (centroidKeyword !== undefined) {
        console.log('Centroid from keyword : ', centroidKeyword);
        // map.setCenter(centroidKeyword);
        MapHelper.autoAdjustZoomAndCenter(map, dataPoints, 25);
      }
    }
    if (prevSelectedChoice.current !== selectedChoice) {
      if (!map) return;

      // adjust zoom until there are <= 25 points on the view port
      MapHelper.autoAdjustZoomAndCenter(map, dataPoints, 25);
    }

    prevKeyword.current = keyword;
    prevSelectedChoice.current = selectedChoice;
  }, [dataPoints]);

  const onLoad = React.useCallback((map: any) => {
    console.debug('Map onLoad');
    setMap(map);
  }, []);
  const onUnmount = React.useCallback((map: any) => {
    setMap(null);
  }, []);

  const checkIfShouldFetch = (): boolean => {
    if (!map) return false;
    const curBounds = map.getBounds();

    const lastCenter = lastFetchedBounds.getCenter();
    const curCenter = curBounds.getCenter();
    const lastLat = lastCenter.lat();
    const lastLng = lastCenter.lng();
    const curLat = curCenter.lat();
    const curLng = curCenter.lng();
    const testDistance = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(lastLat, lastLng),
      new google.maps.LatLng(curLat, curLng),
    );
    console.debug(
      'checkIfShouldFetchAndFetch',
      ' old center ',
      lastLat,
      lastLng,
      ' new center ',
      curLat,
      curLng,
      ' distance between old and new centers',
      testDistance,
    );
    // TODO: check if new center is way off from the last center -> fetchData
    // check if outside of last fetchBound -> fetch data
    if (testDistance > 3000) {
      return true;
    } else {
      console.warn('try go further to get it load');
      return false;
    }
  };

  // Debounce callback
  const [debouncedCallbackBoundsChange] = useDebouncedCallback(
    // function
    () => {
      if (checkIfShouldFetch()) {
        callFetchDataPoint(true);
      } else {
        randomAndSetRandomDataPoints();
      }
    },
    // delay in ms
    WAIT_TIME_BEFORE_FETCH,
  );
  const handleBoundsChanged = () => {
    if (!map) return;
    if (isShowingDirection) return;
    if (!lastFetchedBounds) return;
    const newCenter = {
      lat: map.center.lat(),
      lng: map.center.lng(),
    };
    console.debug(
      'handleBoundsChanged: screen lat',
      prevScreenCenter,
      newCenter,
      prevScreenCenter.current.lat !== newCenter.lat &&
      prevScreenCenter.current.lng !== newCenter.lng,
    );
    if (
      prevScreenCenter.current.lat !== newCenter.lat &&
      prevScreenCenter.current.lng !== newCenter.lng
    ) {
      debouncedCallbackBoundsChange();
    }
    prevScreenCenter.current = newCenter;
  };

  const handleZoomChanged = () => {
    if (map) {
      setZoomText(map.getZoom());
    }
  };

  useEffect(() => {
    refreshCurrentCoordTimer();
  }, []);

  const [currentDate, setCurrentDate] = useState(
    new Date().toLocaleString('sv-SE'),
  );

  const refreshCurrentCoordTimer = async () => {
    setInterval(() => {
      setCurrentDate(new Date().toLocaleString('sv-SE'));
      reReadCurrentLocation();
      renderCenterMaker();
    }, 60000); // every 1 minute
  };

  const renderCenterMaker = () => {
    // has to wait till finish onLoad (map wont be null). ow will get google is not defined
    if (!map) return null;

    return (
      <Marker
        position={currentCoords}
        icon={{
          url: bluePoint,
          scaledSize: new google.maps.Size(28, 28), // scaled size
        }}
      ></Marker>
    );
  };
  const getMapCenter = () => {
    if (!map) return currentCoords;
    else
      return {
        lat: map.center.lat(),
        lng: map.center.lng(),
      };
  };

  const setCenterAndFetchData = async (coords: {
    lat: number;
    lng: number;
  }) => {
    if (map) {
      map.setCenter(coords);
      callFetchDataPoint(true);
    }
  };

  const isDataPointTheViewingPoint = (dataPoint: any) => {
    return viewingPoint && dataPoint.id === viewingPoint.id;
  };

  /**
   * @param dataPoint
   */
  const getPinIconForDataPoint = (dataPoint: any) => {
    if (isDataPointTheViewingPoint(dataPoint)) {
      return {
        url: GreenPin,
        scaledSize: new google.maps.Size(40, 40), // scaled size
      };
    } else {
      return (
        dataPoint.pinIcon && {
          url: dataPoint.pinIcon,
          // if has viewingPoint then scale down
          scaledSize: viewingPoint
            ? new google.maps.Size(26, 37)
            : new google.maps.Size(26, 37),
        }
      );
    }
  };

  const getOpacityForDataPoint = (dataPoint: any) => {
    if (!viewingPoint) return 1;
    else {
      return isDataPointTheViewingPoint(dataPoint) ? 1 : 0.8;
    }
  };

  const renderMarkers = () => {
    // has to wait till finish onLoad (map wont be null). ow will get google is not defined
    // do not show all markers when showing direction
    if (!map || isShowingDirection) return null;

    return toShowDataPoints.map((dp: { [key: string]: any }) => {
      return (
        <Marker
          key={`${dp.id}-${dp.lat}-${dp.lng}`}
          position={new google.maps.LatLng(dp.lat, dp.lng)}
          icon={getPinIconForDataPoint(dp)}
          onClick={async () => {
            handleSetViewingPoint(dp);
            handleSetDrawerState('InfoPanel', 'NORMAL');
          }}
          opacity={getOpacityForDataPoint(dp)}
        />
      );
    });
  };

  const callFetchDataPoint = (immediately = false) => {
    // if repeatedly drag, only fires fetching at the last drag (stopped more than 500 ms)
    if (immediately) {
      withoutDebouncedFetchDataPoints();
    } else {
      debouncedFetchDataPoints();
    }
  };
  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleToggleCollaps = () => {
    setIsCollapsed(!isCollapsed);
  };
  const center = useMemo(() => {
    return translateScreenCenterDown(currentCoords);
  }, [currentCoords]);
  return (
    <SMainMap ref={mainMapRef}>
      {/* <SZoomText
        className={isInputFocus ? 'hide' : ''}
      >{`updated : ${currentDate} | zoom : ${zoomText}z | acc : ${accuracy}m`}</SZoomText> */}
      {useFake ? (
        <FakeImage src={mapImage} />
      ) : (
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}
          libraries={MAP_LIBRARIES}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={defaultProps.zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={() => {
              if (screenMode === 'app') {
                if (viewingPoint) {
                  handleSetViewingPoint(null);
                  collapseDrawer('InfoPanel');
                  handleSetDrawerState('MainFilterPanel', 'NORMAL');
                } else collapseAllDrawer();
              }
              if (screenMode === 'desktop') {
                handleSetViewingPoint(null);
                collapseDrawer('InfoPanel');
                handleSetDrawerState('MainFilterPanel', 'MORE');
                showMobileFullSc && setShowMobileFullSc(true);
              }
            }}
            options={{
              mapTypeControl: false, // to not show satellite map control option
              fullscreenControl: false, // to not show fulscreen control option
              disableDefaultUI: true, // to not show anything
              styles: [
                {
                  featureType: 'administrative.land_parcel',
                  elementType: 'labels',
                  stylers: [
                    {
                      visibility: 'off',
                    },
                  ],
                },
                {
                  featureType: 'administrative.neighborhood',
                  stylers: [
                    {
                      visibility: 'off',
                    },
                  ],
                },
                {
                  featureType: 'poi',
                  stylers: [
                    {
                      visibility: 'off',
                    },
                  ],
                },
                {
                  featureType: 'poi.business',
                  stylers: [
                    {
                      visibility: 'on',
                    },
                  ],
                },
                {
                  featureType: 'poi.park',
                  elementType: 'labels.text',
                  stylers: [
                    {
                      visibility: 'off',
                    },
                  ],
                },
              ],
            }}
            onBoundsChanged={handleBoundsChanged}
            onZoomChanged={handleZoomChanged}
          >
            {renderMarkers()}
            {renderCenterMaker()}
          </GoogleMap>
        </LoadScript>
      )}
    </SMainMap>
  );
};

const MainMapWrapper: React.FC<MainMapProps> = React.forwardRef(
  MainMapFunction,
);
export default MainMapWrapper;

// const SZoomText = styled.div.attrs((props) => ({
//   className: props.className,
// }))`
//   position: absolute;
//   font-size: 0.5rem;
//   // font-weight: 600;
//   top: 0;
//   right: 0;
//   z-index: 10000;
//   margin: 0.3rem;
//   &.hide {
//     z-index: 0;
//   }
// `;

const FakeImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const SMainMap = styled.div`
  // Important! Always set the container height explicitly for map
  height: 100vh;
  width: 100%;
`;
