import { useState, useContext, useMemo } from 'react';

import initApiInstance from '../initApiInstance';
import { LoadingContext } from '../../contexts/LoadingContext';
import { AdminAuthContext } from '../../contexts/AdminAuthContext';

import AdminIconModel from '../../models/AdminIconModel';

const useFetchAdminIcons = () => {
  const [adminIcons, setAdminIcons] = useState<AdminIconModel[]>([]);

  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const fetchAdminIcons = async () => {
    return new Promise<AdminIconModel[]>(async (resolve) => {
      handleSetServiceLoading('fetchAdminIcons', true);
      const data: AdminIconModel[] = await apiInstance
        .get(`admin/icons`, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        })
        .then((res) => {
          console.debug(`admin/icons : `, res);
          return res.data;
        });

      setAdminIcons(data);
      handleSetServiceLoading('fetchAdminIcons', false);
      resolve(data);
    });
  };

  const adminIconsData = useMemo(() => adminIcons, [adminIcons]);

  return { adminIconsData, fetchAdminIcons };
};

const useCreateAdminIcon = () => {
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const createAdminIcon = async (data: AdminIconModel) => {
    return new Promise<boolean>(async (resolve) => {
      handleSetServiceLoading('useCreateAdminIcon', true);
      const success = await apiInstance
        .post(
          `admin/icons`,
          `iconId=${data.iconId}` + `&iconUrl=${data.iconUrl}`,
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          },
        )
        .then((res) => {
          console.debug('POST admin/icons :', res);
          return res.status === 201;
        })
        .catch((err) => false);
      handleSetServiceLoading('useCreateAdminIcon', false);
      resolve(success);
    });
  };
  return { createAdminIcon };
};

const useDeleteAdminIcon = () => {
  const apiInstance = initApiInstance();
  const { handleSetServiceLoading } = useContext(LoadingContext);
  const { jwtToken } = useContext(AdminAuthContext);

  const deleteAdminIcon = async (data: AdminIconModel) => {
    return new Promise<boolean>(async (resolve) => {
      handleSetServiceLoading('useDeleteAdminIcon', true);
      const success = await apiInstance
        .delete(`admin/icons/${data.iconId}`, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        })
        .then((res) => {
          console.debug('DELETE admin/icons :', res);
          return res.status === 200;
        })
        .catch((err) => false);
      handleSetServiceLoading('useDeleteAdminIcon', false);
      resolve(success);
    });
  };
  return { deleteAdminIcon };
};

export default {
  useFetchAdminIcons,
  useCreateAdminIcon,
  useDeleteAdminIcon,
};
