import React, { useContext, useRef } from 'react';

import styled from 'styled-components/macro';

import useCurrentLocation from '../custom-hooks/useCurrentLocation';
import callLogo1 from '../images/call1.png';
import carLogo1 from '../images/sports-car.png';
import carLogo2 from '../images/car1.png';
import { MapInfoContext } from '../contexts/MapInfoContext';
import useOutsideAlerter from '../custom-hooks/useOutsideAlerters';
import { LangContext } from '../contexts/LangContext';
import { ScreenModeContext } from '../contexts/ScreenModeContext';

interface DirectionBarProps {}

const DirectionBar: React.FC<DirectionBarProps> = () => {
  const { coords: currentCoords } = useCurrentLocation();
  const { screenMode } = useContext(ScreenModeContext);
  const {
    viewingPoint,
    handleCancelShowingDirection,
    isShowingDirection,
  } = useContext(MapInfoContext);
  const { currentLangData } = useContext(LangContext);
  const showDirButtonRef = useRef(null);
  useOutsideAlerter(showDirButtonRef, () => {
    handleCancelShowingDirection();
  });

  const switchCarIcon = () => {
    if (isShowingDirection) {
      return carLogo2;
    } else {
      return carLogo1;
    }
  };
  const calling = (viewingPoint: any) => {
    console.log(`Call : ${viewingPoint.telephone}`);
    if (viewingPoint.telephone) {
      window.open(`tel://${viewingPoint.telephone}`, '_top');
    }
  };
  /**
   * open google map with the lat lng
   */
  const handleClickLinkGoogleMap = () => {
    if (screenMode === 'desktop') {
      window.open(
        `https://maps.app.goo.gl/?link=https://www.google.com/maps/dir//${viewingPoint.lat},${viewingPoint.lng}/@${currentCoords.lat},${currentCoords.lng},14z`,
      );
    } else {
      window.location.href = `https://maps.app.goo.gl/?link=https://www.google.com/maps/dir//${viewingPoint.lat},${viewingPoint.lng}/@${currentCoords.lat},${currentCoords.lng},14z`;
    }
  };
  return (
    <SDirectionBar className={`${screenMode}`}>
      {viewingPoint.telephone != null && screenMode != `desktop` && (
        <SButton
          className={`${screenMode}`}
          onClick={() => calling(viewingPoint)}
        >
          <SIconCall src={callLogo1} style={{ height: 16 }} />
          <SLabel>{currentLangData.call}</SLabel>
        </SButton>
      )}
      <SButton
        className={`${screenMode}`}
        ref={showDirButtonRef}
        onClick={() => handleClickLinkGoogleMap()}
      >
        <SIconDirec src={switchCarIcon()} style={{ height: 21 }} />
        <SLabel>{currentLangData.onGoogleMap}</SLabel>
      </SButton>
    </SDirectionBar>
  );
};

export default DirectionBar;

const SIconCall = styled.img`
  height: 16px;
  margin: 0 0.7rem;
`;
const SIconDirec = styled.img`
  height: 21px;
  margin: 0 0.7rem;
`;

const SLabel = styled.div`
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.common.white};
  font-weight: ${(props) => props.theme.fontWeights.title};
  font-family: ${(props) => props.theme.fontFamilies.title};
`;

const SDirectionBar = styled.div`
  font-family: ${(props) => props.theme.fontFamilies.body};
  display: flex;
  width: 100%;
  height: 2.3rem;
  &.app {
    background: ${(props) =>
      props.theme.colors.text.primary}; // #576d70;
  }
  &.desktop {
    background: #00a950;
  }
  position: absolute;
  bottom: 0;
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.25);
  left: 0;
`;

const SButton = styled.button`
  height: 2.3rem;
  width: 100%;
  &.app {
    background: ${(props) => props.theme.colors.text.primary};
  }
  &.desktop {
    background: #00a950;
  }
  border: none;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-of-type {
    border-right: solid 1px
      ${(props) => props.theme.colors.common.white};
  }
`;
