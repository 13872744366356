import React, { useContext, useCallback, useEffect } from 'react';
import { useUpdateEffect } from 'react-use';

import Edit from '@material-ui/icons/Edit';
import Container from '@material-ui/core/Container';

// TABLE
import MaterialTable, { Column, MTableAction } from 'material-table';
import getMaterialTableIcons from '../../helpers/getMaterialTableIcons';

import { PresetContext } from '../../contexts/PresetContext';
import AdminPresetModel from '../../models/AdminPresetModel';
import AdminPresetService from '../../services/admin/AdminPresetService';
import {
  Route,
  Redirect,
  useParams,
  useHistory,
} from 'react-router-dom';
import {
  Grid,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@material-ui/core';
import { FilterContext } from '../../contexts/FilterContext';
import ToggleableButton from '../ToggleableButton';
import styled from 'styled-components/macro';

interface PresetProps {}
/**
 * TIPS: if you have es7 snippets install, you can type rface to get create component template
 */
const Preset: React.FC<PresetProps> = (props) => {
  return (
    <React.Fragment>
      <Route exact path="/preset">
        <Redirect to="/preset/main" />
      </Route>
      <Route
        exact
        path="/preset/:mode"
        render={(props) => {
          if (props.match.params.mode.toLowerCase() === 'main')
            return <PresetTable />;
          else if (props.match.params.mode.toLowerCase() === 'add')
            return (
              <Redirect
                to={`/preset/${props.match.params.mode}/NEW`}
              />
            );
          else return <Redirect to="/preset" />;
        }}
      />
      <Route
        path="/preset/:mode/:url"
        render={(props) => {
          if (props.match.params.mode.toLowerCase() === 'add')
            return <PresetInfo />;
          else if (props.match.params.mode.toLowerCase() === 'edit')
            return <PresetInfo />;
          else return <Redirect to="/preset" />;
        }}
      />
    </React.Fragment>
  );
};

const PresetTable: React.FC<PresetProps> = (props) => {
  interface Row {
    url: string;
    services: string;
    channels: string;
    bankingAgents: string;
  }

  interface TableState {
    columns: Array<Column<Row>>;
    data: Row[];
  }

  const [state, setState] = React.useState<TableState>({
    columns: [
      { title: 'url', field: 'url', width: '10%' },
      {
        title: 'services',
        field: 'services',
        width: '20%',
        cellStyle: { whiteSpace: 'pre' },
      },
      {
        title: 'channels',
        field: 'channels',
        width: '20%',
        cellStyle: { whiteSpace: 'pre' },
      },
      {
        title: 'bankingAgents',
        field: 'bankingAgents',
        width: '50%',
        cellStyle: { whiteSpace: 'pre' },
        render: (rowData) => (
          <SChipButtons>
            {rowData.bankingAgents
              .split(',')
              .map((agent: any, index: number) => {
                return (
                  <SToggleButtonWrapper key={agent}>
                    <ToggleableButton
                      isSelected={false}
                      // imageSrc={choice.buttonLogoUrl}
                      label={agent}
                      onToggle={() => {}}
                    />
                  </SToggleButtonWrapper>
                );
              })}
          </SChipButtons>
        ),
      },
    ],
    data: [],
  });

  const SChipButtons = styled.div`
    display: flex;
    flex-wrap: wrap;
  `;
  const SToggleButtonWrapper = styled.div`
    margin-bottom: 5px;
  `;

  const { presetData, fetchAllPreset } = useContext(PresetContext);
  const {
    deleteAdminPreset,
  } = AdminPresetService.useDeleteAdminPreset();

  const fetchAllPresetWhenDone = useCallback(
    async () => await fetchAllPreset(),
    [],
  );
  useEffect(() => {
    fetchAllPresetWhenDone();
  }, []);
  useUpdateEffect(() => {
    // console.log('ADMIN presetData : ', presetData);

    let presetTableData: Row[] = [];
    const urls = presetData
      .filter(
        (v: AdminPresetModel, i: number) =>
          presetData.findIndex(
            (obj: AdminPresetModel) => obj.url === v.url,
          ) === i,
      )
      .map((v: AdminPresetModel) => v.url);

    urls.forEach((url: string) => {
      presetTableData.push({
        url: url,
        services: `- ${presetData
          .filter(
            (v: AdminPresetModel) =>
              v.url === url && v.filterType === 'service',
          )
          .map((v: AdminPresetModel) => v.filterName)
          .join('\n- ')}`,
        channels: `- ${presetData
          .filter(
            (v: AdminPresetModel) =>
              v.url === url && v.filterType === 'channel',
          )
          .map((v: AdminPresetModel) => v.filterName)
          .join('\n- ')}`,
        bankingAgents: `${presetData
          .filter(
            (v: AdminPresetModel) =>
              v.url === url && v.filterType === 'bankingAgent',
          )
          .map((v: AdminPresetModel) => v.filterName)
          .join(',')}`,
      });
    });

    setState({
      columns: state.columns,
      data: presetTableData,
    });
  }, [presetData]);

  const history = useHistory();

  return (
    <React.Fragment>
      <h1>Preset</h1>
      <Container maxWidth="md">
        <MaterialTable
          icons={getMaterialTableIcons()}
          title=""
          columns={state.columns}
          data={state.data}
          components={{
            Action: (props) => {
              if (
                // only change 'addRow' button
                typeof props.action !== typeof Function
              ) {
                if (props.action.tooltip === 'Add')
                  props.action.onClick = () =>
                    history.push('/preset/add');
              }
              return <MTableAction {...props} />;
            },
          }}
          actions={[
            {
              icon: () => <Edit />,
              tooltip: 'Edit',
              onClick: (event, rowData) =>
                history.push(
                  `/preset/edit/${
                    'url' in rowData ? rowData.url : ''
                  }`,
                ),
            },
          ]}
          editable={{
            onRowAdd: (newData) =>
              new Promise(async (resolve, reject) => {
                // if (await createAdminPreset(newData)) {
                //   resolve();
                //   setState((prevState) => {
                //     const data = [...prevState.data];
                //     data.push(newData);
                //     return { ...prevState, data };
                //   });
                // } else reject();
              }),
            // onRowUpdate: (newData, oldData) =>
            //   new Promise(async (resolve, reject) => {
            //     if (await updateAdminPreset(newData)) {
            //       resolve();
            //       if (oldData) {
            //         setState((prevState) => {
            //           const data = [...prevState.data];
            //           data[data.indexOf(oldData)] = newData;
            //           return { ...prevState, data };
            //         });
            //       }
            //     } else reject();
            //   }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                if (await deleteAdminPreset(oldData.url)) {
                  resolve();
                  setState((prevState) => {
                    const data = [...prevState.data];
                    data.splice(data.indexOf(oldData), 1);
                    return { ...prevState, data };
                  });
                } else reject();
              }),
          }}
        />
      </Container>
    </React.Fragment>
  );
};

const PresetInfo: React.FC<PresetProps> = (props) => {
  const { mode, url } = useParams();
  const isAddMode = !!(mode === 'add');

  const [urlState, setUrlState] = React.useState<string>(
    isAddMode ? '' : url,
  );
  const [state, setState] = React.useState<any>(undefined);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const { presetData, fetchPreset } = useContext(PresetContext);
  const { filterModel, fetchAllMenuSections } = useContext(
    FilterContext,
  );

  const savePreset = isAddMode
    ? AdminPresetService.useCreateAdminPreset().createAdminPreset
    : AdminPresetService.useUpdateAdminPreset().updateAdminPreset;
  const fetchPresetWhenDone = useCallback(async () => {
    await fetchAllMenuSections();
    await fetchPreset(urlState);
  }, []);
  useEffect(() => {
    fetchPresetWhenDone();
  }, []);
  useUpdateEffect(() => {
    let newState: any = {};
    filterModel.oriRawData.forEach(
      (filter: any) =>
        (newState[
          `${filter.filterName}_${filter.filterType}`
        ] = false),
    );
    setState({ ...newState });
  }, [filterModel]);
  useUpdateEffect(() => {
    let newState: any = {};
    Object.entries(presetData).forEach(
      ([k, v]) => (newState[`${k}_${v}`] = true),
    );
    setState({ ...state, ...newState });
  }, [presetData]);

  const history = useHistory();

  return (
    <React.Fragment>
      <h1>PresetInfo</h1>
      <Container maxWidth="md">
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
          // style={{ flexGrow: 1 }}
          spacing={5}
        >
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ color: '#999999' }}
                >
                  Preset URL:
                </Typography>
              </Grid>
              <Grid item>
                {/* INPUT */}
                <TextField
                  color="secondary"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  value={urlState}
                  id="url"
                  name="url"
                  onChange={(e) => setUrlState(e.target.value)}
                  autoFocus
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ color: '#999999' }}
                >
                  Services
                </Typography>
              </Grid>
              <Grid item>
                {state && (
                  <FormGroup row>
                    {filterModel &&
                      filterModel.rawData &&
                      filterModel.rawData.services.map(
                        (v: {
                          filterName: string;
                          filterType: string;
                          labelEn: string;
                        }) => (
                          <FormControlLabel
                            style={{ width: '10vw' }}
                            control={
                              <Checkbox
                                checked={
                                  state[
                                    `${v.filterName}_${v.filterType}`
                                  ]
                                }
                                onChange={handleChange}
                                name={`${v.filterName}_${v.filterType}`}
                              />
                            }
                            label={v.labelEn}
                            key={`${v.filterName}_${v.filterType}`}
                          />
                        ),
                      )}
                  </FormGroup>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ color: '#999999' }}
                >
                  Channels
                </Typography>
              </Grid>
              <Grid item>
                {state && (
                  <FormGroup row>
                    {filterModel &&
                      filterModel.rawData &&
                      filterModel.rawData.channels.map(
                        (v: {
                          filterName: string;
                          filterType: string;
                          labelEn: string;
                        }) => (
                          <FormControlLabel
                            style={{ width: '10vw' }}
                            control={
                              <Checkbox
                                checked={
                                  state[
                                    `${v.filterName}_${v.filterType}`
                                  ]
                                }
                                onChange={handleChange}
                                name={`${v.filterName}_${v.filterType}`}
                              />
                            }
                            label={v.labelEn}
                            key={`${v.filterName}_${v.filterType}`}
                          />
                        ),
                      )}
                  </FormGroup>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography
                  component="h1"
                  variant="h5"
                  style={{ color: '#999999' }}
                >
                  Banking Agents
                </Typography>
              </Grid>
              <Grid item>
                {state && (
                  <FormGroup row>
                    {filterModel &&
                      filterModel.rawData &&
                      filterModel.rawData.bankingAgents.map(
                        (v: {
                          filterName: string;
                          filterType: string;
                          labelEn: string;
                        }) => (
                          <FormControlLabel
                            style={{ width: '10vw' }}
                            control={
                              <Checkbox
                                checked={
                                  state[
                                    `${v.filterName}_${v.filterType}`
                                  ]
                                }
                                onChange={handleChange}
                                name={`${v.filterName}_${v.filterType}`}
                              />
                            }
                            label={v.labelEn}
                            key={`${v.filterName}_${v.filterType}`}
                          />
                        ),
                      )}
                  </FormGroup>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justify="flex-end"
              spacing={2}
            >
              <Grid item xs={1}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  disabled={!urlState || urlState.trim() === ''}
                  onClick={async () => {
                    const data = {
                      url: urlState,
                      oldUrl: url,
                      service: Object.entries(state || {})
                        .filter(
                          ([k, v]) =>
                            v && k.split('_')[1] === 'service',
                        )
                        .map(([k, v]) => k.split('_')[0]),
                      channel: Object.entries(state || {})
                        .filter(
                          ([k, v]) =>
                            v && k.split('_')[1] === 'channel',
                        )
                        .map(([k, v]) => k.split('_')[0]),
                      bankingAgent: Object.entries(state || {})
                        .filter(
                          ([k, v]) =>
                            v && k.split('_')[1] === 'bankingAgent',
                        )
                        .map(([k, v]) => k.split('_')[0]),
                    };

                    const result = await savePreset(data);
                    result && history.push('/preset/main');
                  }}
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={() => history.push('/preset/main')}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Preset;
