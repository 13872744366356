import React, { useContext, useCallback, useEffect } from 'react';
import { useUpdateEffect } from 'react-use';

import Container from '@material-ui/core/Container';

// TABLE
import MaterialTable, { Column } from 'material-table';
import getMaterialTableIcons from '../../helpers/getMaterialTableIcons';

import AdminUserModel from '../../models/AdminUserModel';
import { AdminUserContext } from '../../contexts/AdminUserContext';
import AdminUserService from '../../services/admin/AdminUserService';

interface UserProps {}
/**
 * TIPS: if you have es7 snippets install, you can type rface to get create component template
 */
const User: React.FC<UserProps> = (props) => {
  //   interface Row {
  //     username: string;
  //     password: string;
  //     permission: string;
  //   }

  interface TableState {
    columns: Array<Column<AdminUserModel>>;
    data: AdminUserModel[];
  }

  const [state, setState] = React.useState<TableState>({
    columns: [
      {
        title: 'username',
        field: 'username',
        editable: 'onAdd',
        validate: (rowData) => rowData.username.trim() !== '',
      },
      {
        title: 'password',
        field: 'passwordHashed',
        render: (rowData) =>
          '*'.repeat(rowData.passwordHashed.length),
        validate: (rowData) => rowData.passwordHashed.trim() !== '',
        editComponent: (props) => (
          <input
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            type="password"
          />
        ),
      },
      {
        title: 'permission',
        field: 'permission',
        lookup: { admin: 'Admin', pm: 'Product Manager' },
        validate: (rowData) => rowData.permission.trim() !== '',
      },
    ],
    data: [],
  });

  const { adminUsersData, fetchAdminUsers } = useContext(
    AdminUserContext,
  );
  const { createAdminUser } = AdminUserService.useCreateAdminUser();
  const { updateAdminUser } = AdminUserService.useUpdateAdminUser();
  const { deleteAdminUser } = AdminUserService.useDeleteAdminUser();

  const fetchAdminUsersWhenDone = useCallback(
    async () => await fetchAdminUsers(),
    [],
  );
  useEffect(() => {
    fetchAdminUsersWhenDone();
  }, []);
  useUpdateEffect(() => {
    // console.log('ADMIN adminUsersData : ', adminUsersData);
    setState({
      columns: state.columns,
      data: adminUsersData,
    });
  }, [adminUsersData]);

  return (
    <React.Fragment>
      <h1>User Manager</h1>
      <Container maxWidth="md">
        <MaterialTable
          icons={getMaterialTableIcons()}
          title=""
          columns={state.columns}
          data={state.data}
          initialFormData={{
            username: '',
            passwordHashed: '',
            permission: '',
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise(async (resolve, reject) => {
                if (
                  state.columns
                    .map((obj) => obj.field || '')
                    .some(
                      (val) => !Object.keys(newData).includes(val),
                    ) ||
                  !Object.values(newData).reduce(
                    (acc, val) => acc && val.trim() !== '',
                    true,
                  )
                ) {
                  reject();
                  return {};
                }
                if (await createAdminUser(newData)) {
                  resolve();
                  setState((prevState) => {
                    const data = [...prevState.data];
                    data.push(newData);
                    return { ...prevState, data };
                  });
                } else reject();
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve, reject) => {
                if (
                  state.columns
                    .map((obj) => obj.field || '')
                    .some(
                      (val) => !Object.keys(newData).includes(val),
                    ) ||
                  !Object.values(newData).reduce(
                    (acc, val) => acc && val.trim() !== '',
                    true,
                  )
                ) {
                  reject();
                  return {};
                }
                if (await updateAdminUser(newData)) {
                  if (
                    newData.passwordHashed !==
                    '*'.repeat(newData.passwordHashed.length)
                  )
                    newData.passwordHashed = '*'.repeat(8);
                  resolve();
                  if (oldData) {
                    setState((prevState) => {
                      const data = [...prevState.data];
                      data[data.indexOf(oldData)] = newData;
                      return { ...prevState, data };
                    });
                  }
                } else reject();
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve, reject) => {
                if (await deleteAdminUser(oldData)) {
                  resolve();
                  setState((prevState) => {
                    const data = [...prevState.data];
                    data.splice(data.indexOf(oldData), 1);
                    return { ...prevState, data };
                  });
                } else reject();
              }),
          }}
        />
      </Container>
    </React.Fragment>
  );
};

export default User;
